import React from "react";

export default function LoggedOutWithHeader(props) {
    return (
        <div className="display-logged-out">
            <header>
                <img
                    src="/img/logo_mynumerian.svg"
                    alt="logo"
                />
            </header>
            {props.children}
        </div>
    );
}