import React, { useState } from "react";
import { useParams } from 'react-router-dom';
import { Link } from "react-router-dom";
import "./login.css";

export default function PasswordRecoverConfirm(props) {
    let text = "";
    const params = useParams();

    switch(props.confirm) {
        case "recover":
            text =
                params.again === "again"
                ? "Un nouveau lien a été envoyé à votre adresse mail."
                : "Une demande de réinitialisation de mot de passe a bien été envoyée sur votre adresse mail."
            ;
            break;
        case "reset":
            text = "Le mot de passe a été modifié";
            break;
        case "init":
            text = "La création de votre compte est terminée, vous pouvez vous connecter."
            break;
        default:
            throw new Error("props.confirm : invalid value");
    }
    return (
        <>
            <div id="main-connection">
                <div id="connection-left">
                    <img
                        className="connection-logo"
                        src="/img/logo_mynumerian.svg"
                        alt="logo"
                    />
                    <div>
                        <p className="confirm">{text}</p>
                    </div>
                    <Link className="mdp-oublie-link" to="/">Retour à la page de connexion</Link>
                </div>
                <div id="connection-right"></div>
            </div>
        </>
    );
}
