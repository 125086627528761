import React, { useState } from "react";
import { useParams } from 'react-router-dom';
import { Link, useNavigate } from "react-router-dom";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PrimaryButton from "../../components/ui/PrimaryButton";
import Input from "../../components/forms/Input";
import "./login.css";

function requestRecover(data, navigate, setErrorText, params) {
    fetch(`${PASSWORD_URL}/recover`, {
        method: "POST",
        mode: "cors",
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams(data)
    })
        .then((response) => response.json())
        .then(() => navigate(`/password/recover/confirm/${params.again}`)) // pas d'erreur possible
        .catch(() => setErrorText("Erreur serveur"));
}

export default function PasswordRecover() {
    const [errorText, setErrorText] = useState("");
    const params = useParams();
    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        const data = new FormData(e.target);
        requestRecover(
            {
                email: data.get("useremail"),
            },
            navigate,
            setErrorText,
            params
        );
    };

    let prompt = params.again === "again" ?
    <div>
        <p id="email">Renseignez l'adresse email associée à votre compte utilisateur pour recevoir un nouveau lien.</p>
    </div>
    :
    <div>
        <p className="recover-question">Vous avez oublié votre mot de passe ?</p>
        <p id="email">Renseignez l'adresse email associée à votre compte utilisateur pour recevoir un email qui vous permettra de réinitialiser votre mot de passe.</p>
    </div>

    return (
        <>
            <div id="main-connection">
                <div id="connection-left">
                    <img
                        className="connection-logo"
                        src="/img/logo_mynumerian.svg"
                        alt="logo"
                    />
                    {prompt}
                    {errorText !== "" ? (
                        <div className="error-msg">
                            {errorText}
                        </div>) : null
                    }
                    <form
                        className="connection-form"
                        onSubmit={handleSubmit}
                    >
                        <div className="input-element">
                            <div className="input-with-icon-container">
                                <span>
                                    <FontAwesomeIcon icon={solid("envelope")} />
                                </span>
                                <Input
                                    name="useremail"
                                    placeholder="Adresse email"
                                    type="email"
                                    disabled={false}
                                    required={true}
                                    className="input-with-icon"
                                    ariaLabelledby="email"
                                />
                            </div>
                        </div>
                        <PrimaryButton>Envoyer</PrimaryButton>
                    </form>
                    <Link className="mdp-oublie-link" to="/">Retour à la page de connexion</Link>
                </div>
                <div id="connection-right"></div>
            </div>
        </>
    );
}
