import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import "./loadFailed.css";

export default function LoadFailed() {
    return (
        <div className="load-failed">
            <FontAwesomeIcon icon={solid("triangle-exclamation")} size="xl"/>
            <span>Erreur lors du chargement des données<br/>Veuillez nous excuser pour la gêne occasionée</span>
        </div>
    );
}
