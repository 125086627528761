import React, { useState } from "react";
import { Link, useLoaderData } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import "./modelesAbonnement.css";
import { safeJsonFetch, FAILED } from "../utils";
import VoletDetailsModeleAbonnement from "./VoletDetailsModeleAbonnement"
import LoadFailed from "../components/ui/LoadFailed";
import { CurrentUserContext } from "../context/CurrentUserContext";
import { useContext } from "react";

export async function modelesAbonnementLoader(token) {

    let url;
    if(token) {
        url = `${API_URL}/modeles_abonnement`;
    } else {
        url = `${PUBAPI_URL}/modeles_abonnement`;
    }
    const modelesAbonnement = await safeJsonFetch(url, token);
    return modelesAbonnement;
}

function ModelesAbonnement() {
    const { currentUser } = useContext(CurrentUserContext);
    const [state, setState] = useState({
        souscrisOnly: false,
        voletAffiche: false,
        voletModeleAbonnementId: null,
        voletModeleAbonnementNom: null,
        isDetailAbonnes:false
    });

    function onSeeDetailsButtonClick(modeleAbonnementId, modeleAbonnementNom) {
        setState({
            ...state,
            voletAffiche: true,
            voletModeleAbonnementId: modeleAbonnementId,
            voletModeleAbonnementNom: modeleAbonnementNom,
            isDetailAbonnes: false,
        });
    }

   function onSeeSubscriptionsButtonClick(modeleAbonnementId, modeleAbonnementNom) {
        setState({
            ...state,
            voletAffiche: true,
            voletModeleAbonnementId: modeleAbonnementId,
            voletModeleAbonnementNom: modeleAbonnementNom,
            isDetailAbonnes: true,
        });
    }

    function onVoletCloseButtonClick() {
        setState({
            ...state,
            voletAffiche: false,
            voletModeleAbonnementId: null,
            voletModeleAbonnementNom: null,
            isDetailAbonnes: false,
        });
    }

    function onSouscrisOnlyCheckboxChange(e) {
        // On veut fermer le volet si on restreint la liste des modèles affichés,
        // car le modèle du volet pourrait devenir caché
        const fermerVolet = e.target.checked;
        setState({
            ...state,
            souscrisOnly: e.target.checked,
            voletAffiche: fermerVolet ? false : state.voletAffiche,
            voletModeleAbonnementId: fermerVolet ? null : state.voletModeleAbonnementId,
            voletModeleAbonnementNom: fermerVolet ? null : state.voletModeleAbonnementNom
        });
    }

    let modelesAbonnement = useLoaderData();

    const hasSouscris = modelesAbonnement !== FAILED && modelesAbonnement.some((modeleAbonnement) => modeleAbonnement.souscris);
    const lis = [];
    if (modelesAbonnement !== FAILED) {
        if (state.souscrisOnly) {
            modelesAbonnement = modelesAbonnement.filter((modeleAbonnement) => modeleAbonnement.souscris);
        }
        modelesAbonnement.sort((a, b) => {
            // Lorsque les modèles ont la meme categorie, on trie par nom de modèle
            if (a.categorie === b.categorie) {
                const modeleA = a.nom.toUpperCase();
                const modeleB = b.nom.toUpperCase();
                return modeleA < modeleB ? -1 : 1;
            }

            // On affiche les modèles sans catégorie après ceux avec catégorie
            if (!a.categorie) {
                return 1;
            }

            if (!b.categorie) {
                return -1;
            }

            // Si les modèles ont une categorie différente, on trie par ordre
            // alphabétique de catégorie.
            return a.categorie.toUpperCase() < b.categorie.toUpperCase() ? -1 : 1;
        });

        modelesAbonnement.forEach((modele,index) => {
            let urlSearchParams;
            let lienAbos = null;
            if (modele.souscris) {
                // Quand il n'y a qu'un seul abonnement souscrit pour un modèle d'abonnement, on renvoie vers la page dédiée à cet abonnement
                if (modele.abonnement_souscrit_ids.length === 1) {
                    lienAbos = <Link to={"/abonnement/" + modele.abonnement_souscrit_ids }><span className="souscris" title="Voir l'abonnement">✔ Souscrit</span></Link>;
                } else {
                    // sinon on renvoie vers la liste des abonnements souscrits pour ce modèle d'abonnement
                    // On utilise URLSearchParams pour contruire les paramètres de l'url avec l'ensemble des
                    // abonnementIds qui ont été souscrits pour ce modele d'abonnement
                    urlSearchParams = new URLSearchParams();
                    for (const abonnementId of modele.abonnement_souscrit_ids) {
                        urlSearchParams.append("abonnement_id", abonnementId);
                    }
                    urlSearchParams.append("modele", modele.nom);
                    lienAbos = <Link to={"/abonnements?" + urlSearchParams}><span className="souscris" title="Voir les abonnements">✔ Souscrit</span></Link>
                }
            }

            // On affiche la catégorie des modèles d'abonnement avant les modèles d'une meme categorie
            if (index === 0 || modele.categorie !== modelesAbonnement[index - 1]?.categorie) {
                const couleur = modele.couleur || "var(--bleu-clair)";

                lis.push(
                    <li
                        key={modele.categorie}
                        className="categorie"
                        style={{
                            borderColor: couleur,
                            // On s'assure que le texte de la catégorie ait un contraste suffisant en la mélangeant avec du noir
                            color: `color-mix(in srgb, var(--mix-color-categorie-dark), ${couleur})`
                        }}
                    >
                        {modele.categorie || "Autre" }
                    </li>
                );
            }

            lis.push(
                <li
                    key={modele.id}
                    className={state.voletModeleAbonnementId === modele.id ? "selected" : null}
                    style={{
                        "--couleur-modele": modele.couleur,
                    }}
                >
                    {modele.nom}
                    {lienAbos}
                    <footer>
                        {
                            currentUser.menus.data_collectivites
                                ? (
                                    <button
                                        className="bouton-detail-modele-abonnement abonnes"
                                        onClick={() => onSeeSubscriptionsButtonClick(modele.id, modele.nom)}
                                    >
                                        Voir les abonnés
                                    </button>
                                )
                                : null
                        }
                        <button
                            className="bouton-detail-modele-abonnement"
                            onClick={() => onSeeDetailsButtonClick(modele.id, modele.nom)}
                        >Voir plus</button>
                    </footer>
                </li>
            )
        });
    }

    return (
        <>
            <div className={"modeles-abonnement-container" + (state.voletAffiche === true ? " volet-detail-modele-visible" : "")}>
                <section className="section-contenu-page">
                    <h1 className="titre-page">
                        <FontAwesomeIcon icon={solid("toolbox")} size="sm" className="icone-titre-page" />
                        Tous nos services
                    </h1>
                    {
                        modelesAbonnement !== FAILED
                            ? (
                                <>
                                    {
                                        hasSouscris
                                            ? (
                                                <div className="filtre">
                                                    <label>
                                                        <input type='checkbox' onChange={onSouscrisOnlyCheckboxChange}></input>
                                                        Afficher uniquement les services auxquels vous avez souscrit
                                                    </label>
                                                </div>
                                            )
                                            : null
                                    }
                                    <ul className="liste-modeles-abonnement">
                                        {lis}
                                    </ul>
                                </>
                            )
                            : <LoadFailed/>
                    }
                </section>
                { state.voletAffiche === true
                    ? <VoletDetailsModeleAbonnement
                        key={
                            state.isDetailAbonnes
                                ? `abonnes-${state.voletModeleAbonnementId}`
                                : `detail-${state.voletModeleAbonnementId}`
                        }
                        modeleAbonnementId={state.voletModeleAbonnementId}
                        modeleAbonnementNom={state.voletModeleAbonnementNom}
                        onCloseButtonClick={onVoletCloseButtonClick}
                        isDetailAbonnes = {state.isDetailAbonnes ? true : false}/>
                    : null
                }
            </div>
        </>
    );
}

export default ModelesAbonnement;
