import React, { useState }  from "react";
import { useLoaderData,useParams, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import BadgeRenouvellementAbonnement from "../components/ui/BadgeRenouvellementAbonnement";
import "./abonnements.css";
import { safeJsonFetch, FAILED } from "../utils";
import BoutonTri from "../components/ui/BoutonTri";
import LoadFailed from "../components/ui/LoadFailed";
import PrixHT from "../components/ui/PrixHT";
import EmptyAbonnementListeMessage from "../components/ui/EmptyAbonnementListeMessage";

export async function abonnementsLoader({params},token) {
    let url;
    let collectiviteData;
    if(params?.collectiviteId) {
        collectiviteData = await safeJsonFetch(
            `${API_URL}/admin/collectivite/${params.collectiviteId}`,
           token
        )

        const searchParams = new URLSearchParams({
            only_abonnements: true,
            sort_desc: true,
        });
        url = `${API_URL}/admin/collectivite/${params.collectiviteId}/abonnements?${searchParams}`;
    } else {
        url = `${API_URL}/abonnements`;
    }
    const abonnements = await safeJsonFetch(
        url,
       token
    );
    return {
        abonnements,
        collectiviteData,
    };
}

const LABELS = {
    ABONNEMENT: "Abonnement",
    DATE_DEBUT: "Date de début",
    DATE_FIN: "Date de fin",
    PRIX: "Prix",
}

const DATE_FORMAT_OPTIONS = {
    day: "2-digit",
    month: "2-digit",
    year: "2-digit"
}

const TRI_DIRECTION = {
    asc: "ASC",
    desc: "DESC",
}

const TRI_CLES = {
    date_debut: {cle: "date_debut", libelle: "Date de début"},
    date_fin: {cle: "date_fin", libelle: "Date de fin"},
    statut: {cle: "statut", libelle: "Statut"},
    modele_abonnement: {cle: "modele_abonnement", libelle: "Type"},
    prix_total_annuel: {cle: "prix_total_annuel", libelle: "Prix"},
}

function Abonnements(props) {
    const params = useParams();
    const searchParams = new URLSearchParams(document.location.search);
    const filtreAbonnementIds = searchParams.getAll("abonnement_id");

    // Par défaut, la liste est triée par statut et ordre ascendant
    const [state, setState] = useState({
        cleTri: TRI_CLES.statut.cle,
        directionTri: TRI_DIRECTION.asc,
    });

    const setCleTri = (cle) => setState((prevState) => ({
        cleTri: cle,
        // si l'utilisateur clique sur le meme bouton de tri et que l'ordre de tri était ascendant
        // alors on trie par ordre descendant
        // sinon l'ordre de tri est acensdant
        directionTri: prevState.cleTri === cle && prevState.directionTri === TRI_DIRECTION.asc
            ? TRI_DIRECTION.desc
            : TRI_DIRECTION.asc
        }))
    let {
        abonnements,
        collectiviteData,
    } = useLoaderData();

    const trs = [];

    let titrePage = !props.vueAdmin
        ? 'Vos contrats'
        : "Contrats" + (collectiviteData !== FAILED ? " - " + collectiviteData.nom : "")

    if (abonnements !== FAILED) {
        // On filtre les abonnements dont l'id est en parametre de l'url afin d'afficher uniquement les abonnements correspondants
        if (filtreAbonnementIds && filtreAbonnementIds.length > 0) {
            abonnements = abonnements.filter(abonnement => filtreAbonnementIds.includes(`${abonnement.id}`));

            const filtreNom = searchParams.get('article') || searchParams.get('modele');
            if (filtreNom) {
                titrePage +=  ": " + filtreNom
            }
        }

        abonnements.sort((a, b) => {
            let propA = a[state.cleTri];
            let propB = b[state.cleTri];

            // Si un abonnement n'a pas la propriété correspondante à cleTri (par exemple date_fin)
            // alors on veut que l'abonnement apparaisse avant un abonnement pour lequel la propriété
            // est renseignée.
            if(propA === false ||  propB === false) {
                if (propA === propB) {
                    return 0;
                }

                if (propA === false) {
                    return -1
                }

                return 1;
            }

            if (propA < propB) {
                return -1;
            }
            if (propA > propB) {
                return 1;
            }

            return 0;
        });

        if (state.directionTri === TRI_DIRECTION.desc) {
            abonnements.reverse();
        }


        abonnements.forEach(abonnement => {
            const abonnementLink = params?.collectiviteId
            ? `/collectivites/${params.collectiviteId}/abonnement/${abonnement.id}`
            : "/abonnement/" + abonnement.id;

            trs.push(
                <tr key={abonnement.id}>
                    <td data-statut-abonnement={abonnement.statut} className="statut-abonnement">
                        <FontAwesomeIcon icon={solid("file-signature")} size="2xl"/>
                        <div className="texte-statut-abonnement">
                            {abonnement.statut.toUpperCase()}
                        </div>
                    </td>
                    <td>
                        <span className="nom-abonnement">{abonnement.modele_abonnement}</span>
                        <div className="infos-abonnement">
                            <span className="code-abonnement">{abonnement.code}</span>
                            <BadgeRenouvellementAbonnement abonnement={abonnement}/>
                        </div>
                    </td>
                    <td>
                        <span className="small-label">{LABELS.DATE_DEBUT}:&nbsp;</span>
                        <span className="date-abonnement">{new Date(abonnement.date_debut).toLocaleDateString(undefined, DATE_FORMAT_OPTIONS)}</span>
                    </td>
                    <td>
                        <span className="small-label">{LABELS.DATE_FIN}:&nbsp;</span>
                        <span className="date-abonnement">{abonnement.date_fin ? new Date(abonnement.date_fin).toLocaleDateString(undefined, DATE_FORMAT_OPTIONS) : ""}</span>
                    </td>
                    <td>
                        <span className="small-label prix-abonnnement">{LABELS.PRIX}:&nbsp;</span>
                        <span className="prix-abonnnement"><PrixHT prix={abonnement.prix_total_annuel}/></span>
                    </td>
                    <td className="lien-vue-abonnement">
                        <Link to={abonnementLink}>Voir</Link>
                    </td>
                </tr>
            );
        });
    }

    return (
        <>
            <section className={"section-contenu-page page-abonnements" + (props.vueAdmin ? " page-abonnements-admin" : "")}>
                <h1 className="titre-page">
                    <div>
                        <FontAwesomeIcon icon={solid("pen-nib")} size="sm" className="icone-titre-page"/>
                        { titrePage }
                    </div>
                </h1>
                {
                    filtreAbonnementIds && filtreAbonnementIds.length > 0
                        ? <div className="abonnements-wrapper-liens"><Link className="lien-vue-all-abonnements" to={"/abonnements"}>Voir tous vos contrats</Link></div>
                        : null
                }
                {
                    abonnements === FAILED
                        ? <LoadFailed/>
                        : abonnements.length === 0
                            ? <EmptyAbonnementListeMessage class={'empty-liste-abonnements-container'}/>
                            : (
                                <table className="liste-abonnements table-buc">
                                    <caption className="tri-abonnements">
                                        Trier par:
                                        {Object.entries(TRI_CLES).map((entry, i) => (
                                            <BoutonTri
                                                key={i}
                                                cle={entry[0]}
                                                cleTri={state.cleTri}
                                                directionTri={state.directionTri}
                                                handleOnClick={setCleTri}
                                            >
                                                {entry[1].libelle}
                                            </BoutonTri>
                                        ))}
                                    </caption>
                                    <thead>
                                        <tr>
                                            <th colSpan="2">{LABELS.ABONNEMENT}</th>
                                            <th>{LABELS.DATE_DEBUT}</th>
                                            <th>{LABELS.DATE_FIN}</th>
                                            <th>{LABELS.PRIX}</th>
                                            <th aria-label="lien pour consulter les détails de l'abonnement"></th>
                                        </tr>
                                    </thead>
                                    <tbody>{trs}</tbody>
                                </table>
                            )
                }
            </section>
        </>
    );
}

export default Abonnements;
