import React, { useState } from "react";
import { useLoaderData, useNavigate } from "react-router-dom";
import useToken from "./../hooks/useToken";
import { safeJsonFetch,safeFetch, FAILED } from "../utils";
import LoadFailed from "../components/ui/LoadFailed";
import "./formulaireTicket.css";

export async function formStructureLoader (token) {
    const formStructure = await safeJsonFetch(
        `${API_URL}/structure_formulaire_ticket`,
       token
    );

    return {
        formStructure,
    }
}

function FormulaireTicket() {
    const [state, setState] = useState({
        creating: false,
        created: null,
        questions: null
    });

    const { token } = useToken();
    const { formStructure } = useLoaderData();
    const navigate = useNavigate();
    const goBack = () => {
		navigate(-1);
	}

    function getReactElementFromQuestionStructure(questionStructure) {
        let content;
        const description = questionStructure.description
            ? (
                <div
                    className="description-question"
                    dangerouslySetInnerHTML={{__html:questionStructure.description}}>
                </div>
            )
            : null;

        if (questionStructure.type === 'text') {

            // questionStructure.regex peut contenir les slash de debut et de fin representant une
            // regex, mais l'attribut pattern n'en a pas besoin.
            let pattern = questionStructure.regex;
            if (pattern.startsWith("/") && pattern.endsWith("/")) {
                pattern = pattern.substring(1, pattern.length - 1);
            }

            content = (
                <label>
                    {questionStructure.nom}{description}
                    <input
                        type="text"
                        name={getQuestionInputName(questionStructure.id)}
                        required={questionStructure.required === 1 ? true : undefined}
                        pattern={pattern || null}
                        minLength={questionStructure.min_length !== "0" ? questionStructure.min_length : null}
                        maxLength={questionStructure.max_length !== "0" ? questionStructure.max_length : null}
                        data-question-id={questionStructure.id}
                        onChange={onInputChange}
                    ></input>
                </label>
            );
        } else if(questionStructure.type === 'textarea') {
            content = (
                <label>
                    {questionStructure.nom}{description}
                    <textarea
                        name={getQuestionInputName(questionStructure.id)}
                        rows="10"
                        required={questionStructure.required === 1 ? true : undefined}
                        data-question-id={questionStructure.id}
                        onChange={onInputChange}
                    ></textarea>
                </label>
            );
        } else if(questionStructure.type === 'checkboxes') {
            const checkboxGroupLabelId = "checkbox-label-question-" + questionStructure.id;
            content = (
                <div aria-labelledby={checkboxGroupLabelId} className="question-structure-container">
                    <span id={checkboxGroupLabelId} className={questionStructure.required === 1 ? "required" : null}>{questionStructure.nom}</span>{description}
                    {
                        questionStructure.valeurs.map(valeur =>
                            <label className="label-light">
                                <input
                                    type="checkbox"
                                    name={getQuestionInputName(questionStructure.id)}
                                    value={valeur}
                                    data-question-id={questionStructure.id}
                                    onChange={onInputChange}
                                ></input>
                                {valeur}
                            </label>
                        )
                    }
                </div>
            );
        } else if(questionStructure.type === 'select') {
            content = (
                <label>
                    {questionStructure.nom}{description}
                    <select
                        name={getQuestionInputName(questionStructure.id)}
                        required={questionStructure.required === 1 ? true : undefined}
                        data-question-id={questionStructure.id}
                        onChange={onInputChange}
                    >
                        {
                            [
                                <option value="">---</option>,
                                ...questionStructure.valeurs.map(valeur =>
                                    <option value={valeur}>{valeur}</option>
                                )
                            ]
                        }
                    </select>
                </label>
            )
        } else if(questionStructure.type === 'radios') {
            const radioGroupLabelId = "radio-label-question-" + questionStructure.id;
            content = (
                <div role="radiogroup" aria-labelledby={radioGroupLabelId} className="question-structure-container">
                    <span id={radioGroupLabelId}>{questionStructure.nom}</span>{description}
                    {
                        questionStructure.valeurs.map(valeur =>
                            <label className="label-light">
                                <input
                                    type="radio"
                                    data-question-id={questionStructure.id}
                                    name={getQuestionInputName(questionStructure.id)}
                                    value={valeur}
                                    required={questionStructure.required === 1 ? true : undefined}
                                    onChange={onInputChange}
                                ></input>
                                {valeur}
                            </label>
                        )
                    }
                </div>
            );

        } else {
            return null;
        }

        return (
            <div key={questionStructure.id}>
                {content}
            </div>
        );
    }

    function getQuestionInputName(questionId) {
        return `question-${questionId}`;
    }

    function shouldElementBeVisible(regleAffichage, regles, formData) {
        const visible = true;
        const hidden = false;

        // regleAffichage: 1 -> la section/question est toujours visible
        if (regleAffichage === 1) {
            return visible;
        }

        // FormCreator utilise une librairie (https://github.com/xylemical/php-expressions/blob/master/src/Evaluator.php#L27C13-L27C66)
        // qui se base sur la notation polonaise inverse https://fr.wikipedia.org/wiki/Notation_polonaise_inverse

        // On va donc parcourir les règles de la dernière à la première, regarder sa validité
        // et comparer avec le resultat de la précédente itération.
        // Donc si on a ["OR a", "OR b", "AND c"], on fera le calcul suivant: ((c && b) || a)
        // On construit un nouveau tableau car `reverse` transforme le tableau initial.
        const valideRegles = Array.from(regles).reverse().reduce((acc, regle, i, arr) => {
            const valide = isRegleValide(formData, regle);
            //  Pour la première itération, on initialise la variable du resultat final
            if (i === 0) {
                return valide;
            }

            // Ensuite, on va "calculer" la nouvelle valeur en la comparant avec la valeur
            // obtenue lors de l'iteration précédente.
            // On doit récuperer l'operateur de l'élément précédent
            const logiqueAffichage = arr[i - 1].logique_affichage;

            // Valeurs possibles pour logique_affichage:
            // 1: AND
            // 2: OR
            if (logiqueAffichage === 1) {
                return acc && valide;
            } else if (logiqueAffichage === 2) {
                return acc || valide;
            }
            // ce cas n'arrive pas, mais on le met pour satisfaire eslint
            return acc;
        },
        // Par defaut, on ne peut pas dire si les regles sont valides ou non, donc on commence avec undefined.
        // Meme si cela ne joue pas dans notre logique, car on n'utilise pas acc lors de la première itération.
        // Il est important de passer ce second argument sinon le callback n'est pas appelé
        // pour le premier element du tableau, et sa valeur est directement mise dans acc pour
        // l'iteration sur le second element.
        undefined
        );

        // regleAffichage : 2 -> Masqué par défaut, sauf si
        if (regleAffichage === 2) {
            // Si il n'y a pas de regles, l'élément est caché
            if (regles.length === 0) {
                return hidden;
            }

            // Sinon, il est visible si il a validé les regles
            return valideRegles ? visible : hidden;
        }

        // regleAffichage : 3 -> Affiché par défaut, sauf si
        if (regleAffichage === 3) {
            // Si il n'y a pas de regles, l'élément est affiché
            if (regles.length === 0) {
                return visible;
            }

            // Sinon, il est caché si il a validé les regles
            return valideRegles ? hidden : visible;
        }

        // On ne devrait problablement pas avoir d'autres regleAffichage, si jamais c'est le cas,
        // on n'affiche pas la section/question.
        return hidden;
    }

    function isRegleValide(formData, regle) {
        const regleQuestionName = getQuestionInputName(regle.question_id);
        const values = formData && formData.has(regleQuestionName)
            ? formData.getAll(regleQuestionName)
            : null;

        // condition_affichage: 1 -> égal
        if (regle.condition_affichage === 1) {
            return values ? values.includes(regle.valeur_affichage) : false;
        }
        // condition_affichage: 2 -> différent
        if (regle.condition_affichage === 2) {
            return values ? !values.includes(regle.valeur_affichage) : false;
        }
        // condition_affichage: 3 -> inferieur
        if (regle.condition_affichage === 3) {
            return values ? values[0] < regle.valeur_affichage : false;
        }
        // condition_affichage: 4 -> superieur
        if (regle.condition_affichage === 4) {
            return values ? values[0] > regle.valeur_affichage : false;
        }
        // condition_affichage: 5 -> inferieur ou égal
        if (regle.condition_affichage === 5) {
            return values ? values[0] <= regle.valeur_affichage : false;
        }
        // condition_affichage: 6 -> supérieur ou égal
        if (regle.condition_affichage === 6) {
            return values ? values[0] >= regle.valeur_affichage : false;
        }
        // condition_affichage: 7 -> est visible
        if (regle.condition_affichage === 7) {
            // La question est affichée si ses regles sont validées, on doit donc récuperer
            // la question (et ses regles d'abord)
            let questionRegle = getQuestionbyId(regle.question_id);

            // Si on a pas trouvé la question, alors on considère qu'elle n'est pas visible
            if (!questionRegle) {
                return false;
            }

            // Et on regarde si ces regles sont valides, i.e. si la question sera affichée
            // Cette recursion pourrait potentiellement poser probleme pour l'affichage du formulaire
            // dans le cas où le formulaire GLPI serait mal configuré.
            // Dans ce cas,c'est un problème propre à GLPI, il faudra donc corriger du cote de GLPI.
            return shouldElementBeVisible(questionRegle.regle_affichage, questionRegle.regles, formData);
        }
        // condition_affichage: 8 -> n'est pas visible
        if (regle.condition_affichage === 8) {
            // La question n'est pas affichée si ses regles ne sont pas validées,
            // on doit donc récuperer la question (et ses regles d'abord)
            let questionRegle = getQuestionbyId(regle.question_id);

            // Si on a pas trouvé la question, alors on considère qu'elle n'est pas visible
            if (!questionRegle) {
                return true;
            }

            // Et on regarde si ces regles sont valides, i.e. si la question sera affichée
            return !shouldElementBeVisible(questionRegle.regle_affichage, questionRegle.regles, formData);
        }
        // condition_affichage: 9 -> valide une expression regulière
        if (regle.condition_affichage === 9) {
            let regex = regle.valeur_affichage;
            if (regex.startsWith("/") && regex.endsWith("/")) {
                regex = regex.substring(1, regex.length - 1);
            }
            return values ? new RegExp(regex).test(values[0]) : false;
        }

        // On ne devrait problablement pas avoir d'autres type de conditions, si jamais c'est le cas,
        // on n'affiche pas la question.
        return false;
    }

    function getQuestionbyId(id) {
        for (const section of formStructure.sections) {
            for (const question of section.questions) {
                if (question.id === id) {
                    return question;
                }
            }
        }
    }

    const onInputChange = (event) => {
        const input = event.target;

        // On gère le cas particulier 'required' des checkboxes
        if (input.getAttribute("type") === "checkbox") {
            for (const checkbox of input.closest("form").querySelectorAll(`[name="${input.getAttribute("name")}"]`)) {
                checkbox.setCustomValidity("");
                checkbox.reportValidity();
            }
        }

        const valueChanged = state.questions &&
            state.questions.get(input.getAttribute("name")) !== input.value;

        const formData = new FormData(input.closest("form"));

        // Si l'utilisateur a modifié une valeur existante, il se peut que l'on ne veuille plus
        // afficher certaines sections/questions
        if (valueChanged) {
            const cleanupFormData = () => {
                // On parcourt donc les differentes sections
                formStructure.sections.forEach(sectionStructure => {
                    // Si la section n'a plus de règle valide, alors on enlève les valeurs de ses
                    // questions du state
                    const isSectionValide = shouldElementBeVisible(sectionStructure.regle_affichage, sectionStructure.regles, formData);
                    sectionStructure.questions.forEach(question => {
                        const questionName = getQuestionInputName(question.id);

                        if (formData.has(questionName) && (
                            // Si la section n'est plus valide
                            !isSectionValide ||
                            // ou la question n'est plus valide,
                            !shouldElementBeVisible(question.regle_affichage, question.regles, formData))
                        ){
                            // alors on enlève la valeur du state.
                            formData.delete(questionName)
                            // Et on relance la procedure de nettoyage, car il y a peut etre
                            // un impact sur une autre regle
                            cleanupFormData();
                        }
                    })
                })
            }
            cleanupFormData();
        }

		setState({
            ...state,
            questions: formData
        })
	};

    let sections;
    if (formStructure !== FAILED) {
        // on ne veut afficher que les sections dont les règles sont validées
        sections = formStructure.sections
             .filter(sectionStructure => {
                return shouldElementBeVisible(sectionStructure.regle_affichage, sectionStructure.regles, state.questions);
            })
            // on trie par l'ordre defini dans GLPI
            .sort((a, b) => a.ordre < b.ordre ? -1 : 1)
            .map(sectionStructure => {
                // On n'affiche que les questions de la section dont les règles sont validées
                const fields = sectionStructure.questions
                    .filter(questionStructure => {
                        return shouldElementBeVisible(questionStructure.regle_affichage, questionStructure.regles, state.questions);
                    })
                    // on trie par l'ordre defini dans GLPI
                    .sort((a, b) => a.ordre < b.ordre ? -1 : 1)
                    // et on crée les champs de formulaire correspondants
                    .map(questionStructure => {
                        return getReactElementFromQuestionStructure(questionStructure)
                    });

                return (
                    <fieldset key={sectionStructure.id}>
                        <legend>{sectionStructure.nom}</legend>
                        {fields}
                    </fieldset>
                )
            })
    }

    const onFormSubmit = async e => {
        // création automatique dans GLPI
        e.preventDefault();

        // Initialise le formData avec les valeurs du formulaire
        const formData = new FormData(e.target);

        // On gère le cas particulier 'required' des checkboxes:
        // On ne peut pas ajouter un attribut 'required' à chaque input de type checkbox
        // sinon chaque checkbox devrait etre cochée.
        // On veut juste qu'au moins une checkbox soit cochée
        // Pour le vérifier:
        // On parcourt les differentes sections
        for (const sectionStructure of formStructure.sections) {
            //et les différentes questions de chaque section

            for (const question of sectionStructure.questions) {
                if(question.type === 'checkboxes' && question.required === 1) {
                    // toutes les checkboxes ont le meme name, donc checks corespond à un tableau
                    // avec autant d'elements qu'il y a de checkbox coché
                    const inputName = getQuestionInputName(question.id)
                    const checks = formData.getAll(inputName);

                    // si aucune checkbox est cochée
                    if (checks.length === 0) {
                        const input = e.target.querySelector(`[name = ${inputName}]`)
                        // si le champ est bien affiché
                        if(input !== null) {
                            input.setCustomValidity("Veuillez selectionner au moins une de ces options.");
                            input.reportValidity();
                            // On ne continue pas car un champ requis n'est pas renseigné
                            return;
                        }
                    }
                }
            }
        }

        setState({
            ...state,
            creating: true,
            created: null,
        });

        const body = new FormData();

        // formStructure.target.target_name contient la structure permettant de génerer le titre
        // du ticket en fonction de la réponse à certaines questions.
        // Les parties à remplacer sont sous la forme ##answer_xx##, où xx est l'id de la question.
        const answerRegex = /##answer_(\d+)##/gi;
        // On remplace donc chaque occurence recherchée par la valeur de la question associée
        let titre = formStructure.target.target_name.replace(answerRegex, (match, questionId) => {
          return formData.get(getQuestionInputName(questionId));
        });
        // et on définit le titre dans le formData
        body.set("name", titre);

        // Le plugin construit le content du ticket par rapport au formulaire suivant une structure précise.
        // D'abord on a un h1
        let content='<h1 class="titre-donnees-formulaire">Données du formulaire</h1>';
        let countQuestion = 0;
        // Puis des h2 pour chaque section affichée
        for (const fieldset of e.target.querySelectorAll("fieldset")) {
            const section = fieldset.querySelector("legend").innerText;
            content += `${countQuestion !== 0 ? '<br>' : ''}<h2 class='sous-titre-donnees-formulaire'>${section}</h2>`;

            for(const [name, value] of formData.entries()) {
                // Si le champ n'est pas dans la section en cours, on l'ignore
                const input = fieldset.querySelector(`[name=${name}]`);
                if (!input) {
                    continue;
                }

                countQuestion++;

                const questionId = parseInt(input.getAttribute("data-question-id"));
                const question = getQuestionbyId(questionId);

                let valeur;
                if(question.type === 'textarea') {
                    // Pour les textarea, le plugin de glpi entoure chaque ligne dans une balise p.
                    // On fait donc de meme pour avoir un affichage cohérent.
                    valeur = value.split("\n")
                        // le plugin force aussi les lignes vides à avoir un espace pour que la ligne
                        // prenne de l'espace verticale.
                        .map((ligne) =>`<p>${ligne || "&nbsp;" }</p>`)
                        .join('');
                } else {
                    valeur = value;
                }

                content +=  `<div><b>${countQuestion}) ${question.nom} : </b>${valeur}</div>`;
            }
        }

        body.set("content", content);

        try {
            const response = await safeFetch(
                `${API_URL}/ticket/create`,
                token,
                {
                    method: "POST",
                    body,
                }
            );
            if (response === FAILED) {
                throw new Error();
            }

            const data = await response.json();
            if (data === false) {
                throw new Error();
            }

            // redirection vers page detail du ticket
            return navigate(`/ticket/${data.id}`);
        } catch(ex) {
            setState({
                ...state,
                created: false,
                creating: false,
            });
        }
    };

    return (
        <section className="section-contenu-page page-statique">
            <h1 className="titre-page">Créer une demande d'assistance</h1>
            {
                (formStructure === FAILED)
                    ? <LoadFailed/>
                    : (
                        <form
                            className="formulaire-ticket"
                            onSubmit={onFormSubmit}
                        >
                            {
                                state.created === false
                                ? <div className="error-msg">Le ticket n'a pas pu être créé.<br/>Veuillez réessayer ultérieurement.</div>
                                : null
                            }
                            {sections}
                            <section className="boutons-action">
                                <button
                                    type="submit"
                                    className="submit-btn"
                                    disabled={state.creating ? true : null}
                                >
                                    {state.creating ? "Enregistrement" : "Créer"}
                                </button>
                                <button
                                    type="button"
                                    className="secondary-btn"
                                    disabled={state.creating ? true : null}
                                    onClick={goBack}
                                >
                                    Annuler
                                </button>
                            </section>
                        </form>
                    )
            }
        </section>
    );
}

export default FormulaireTicket;