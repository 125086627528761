import React, { useState } from "react";
import useToken from "./../hooks/useToken";
import { safeFetch, FAILED } from "../utils";
import "./formulaireContact.css";

export default function FormulaireContact() {
    const [state, setState] = useState({
        sending: false,
        sent: null,
    });

    const { token } = useToken();

    const onFormSubmit = async e => {
        setState({
            sending: true,
            sent: null,
         });

        e.preventDefault();
        const formData = new FormData(e.target);
        try {
            const response = await safeFetch(
                `${API_URL}/contact/form_submit`,
                token,
                {
                    method: "POST",
                    body: formData,
                }
            );
            if (response === FAILED) {
                throw new Error();
            }

            const data = await response.json();
            if (data === false) {
                throw new Error();
            }

            setState({
               sent: true,
               sending: false,
            });
        } catch(ex) {
            setState({
                sent: false,
                sending: false,
             });
        }
    }

    return (
        <section className="section-contenu-page page-statique">
            <h1 className="titre-page">Nous contacter</h1>
            <h2 className="sous-titre-page-statique">Par email</h2>
            <form className="formulaire-contact" onSubmit={onFormSubmit}>
                {
                    state.sent === false
                        ? <div className="error-msg">Le message n'a pas pu être envoyé.<br/>Veuillez réessayer ultérieurement.</div>
                        : null
                }
                {
                    state.sent === true
                        ? <div className="success-msg">Le message a bien été envoyé.<br/>Nous vous répondrons prochainement.</div>
                        : null
                }
                <section>
                    <label htmlFor="objet">Objet</label>
                    <input id="objet" name="objet" placeholder="saisissez l'objet de votre demande" required disabled={state.sending ? true : undefined}/>
                </section>
                <section>
                    <label htmlFor="thematique">Thématique</label>
                    <select id="thematique" name="thematique" required disabled={state.sending ? true : undefined}>
                        <option value="">Sélectionnez une thématique</option>,
                        <option value="Renseignement sur nos offres">Renseignement sur nos offres</option>,
                        <option value="Besoin spécifique">Besoin spécifique</option>,
                        <option value="Idée à nous communiquer">Idée à nous communiquer</option>,
                        <option value="Réclamation">Réclamation</option>,
                        <option value="Autre">Autre</option>,
                    </select>
                </section>
                <section>
                    <label htmlFor="message">Message</label>
                    <textarea id="message" name="message" required placeholder="saisissez votre message" rows="15" disabled={state.sending ? true : undefined}/>
                </section>
                <button className="submit-btn" type="submit" disabled={state.sending ? true : undefined}>Envoyer</button>
            </form>
            <section className="contact-tel">
                <h2 className="sous-titre-page-statique">Par téléphone</h2>
                <p>
                    <a
                        href="tel:+33475301313">
                        04 75 30 13 13
                    </a><br/>
                    Du lundi au vendredi<br/>
                    De 8h30 à 12h<br/>
                    et de 13h30 à 17h30
                </p>
            </section>
        </section>
    );
}
