import React, { useState } from "react";
import { useLoaderData, useParams, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { safeJsonFetch, FAILED, localeIncludes } from "../utils";
import LoadFailed from "../components/ui/LoadFailed";
import "./tickets.css";
import Input from "../components/forms/Input";

export async function ticketsLoader({ params },token) {
    let url;
    let collectiviteData;
    if (params?.collectiviteId) {
        collectiviteData = await safeJsonFetch(
            `${API_URL}/admin/collectivite/${params.collectiviteId}`,
           token
        )

        const searchParams = new URLSearchParams({
            only_tickets: true,
            sort_desc: true,
        });
        url = `${API_URL}/admin/collectivite/${params.collectiviteId}/tickets?${searchParams}`;
    } else {
        url = `${API_URL}/tickets`;
    }
    const tickets = await safeJsonFetch(
        url,
       token
    );
    return {
        tickets,
        collectiviteData,
    };
}

const FILTRE_STATUTS = {
    nouveau: "Nouveau",
    en_cours: "En cours",
    en_attente: "En attente",
    resolu: "Résolu",
    clos: "Clos"
}

function Tickets (props) {
    // Par défaut, la liste est filtrée par statut 'Nouveau', 'En cours', 'En attente' et 'Résolu'
    const [state, setState] = useState({
        filtreText: "",
        filtreStatuts :
            props.vueAdmin
            ? [FILTRE_STATUTS.nouveau, FILTRE_STATUTS.en_cours, FILTRE_STATUTS.en_attente, FILTRE_STATUTS.resolu, FILTRE_STATUTS.clos]
            : [FILTRE_STATUTS.nouveau, FILTRE_STATUTS.en_cours, FILTRE_STATUTS.en_attente, FILTRE_STATUTS.resolu]
    });

    const params = useParams();

    function onFilterCheckboxChange(e) {
        if(e.target.checked) {
            setState({
                ...state,
                filtreStatuts: [...state.filtreStatuts, e.target.value],
            });
        } else {
            setState({
                ...state,
                filtreStatuts: state.filtreStatuts.filter((statut) => statut !== e.target.value),
            })
        }
    }

    function onFilterInputTextChange(e) {
        setState({
            ...state,
            filtreText: e.target.value,
        });
    }

    let {
        tickets,
        collectiviteData,
    } = useLoaderData();

    const lis = [];

    if (tickets !== FAILED) {
        // on trie par ordre descendant selon la date de MAJ
        tickets.sort((a, b) => {
            const dateA = a.date_modification;
            const dateB = b.date_modification;
            if (dateA < dateB) {
                return 1;
            }
            if (dateA > dateB) {
                return -1;
            }

            return 0;
        });

        tickets.forEach(ticket => {
            const dateOuverture = new Date(ticket.date_ouverture).toLocaleDateString();
            const dateModification = new Date(ticket.date_modification).toLocaleDateString();

            // Les entités commencent toutes par 'Entité racine > ' suivi du nom,
            // on ne veut afficher que la chaine de caractères qui suit.
            const entite = ticket.entite.replace(/^Entité racine > /, "");

            const matchStatus = state.filtreStatuts.includes(ticket.statut) ||
                (state.filtreStatuts.includes("En cours") && ticket.statut.startsWith("En cours") );
            const matchText = matchTexteTicket([
                ticket.statut,
                // on concatène la catégorie et le titre pour se calquer sur l'affichage
                ticket.categorie + ":" + ticket.titre,
                entite,
                dateOuverture,
                dateModification,
            ], state.filtreText)

            if(matchStatus && matchText) {
                const ticketLink = params?.collectiviteId
                    ? `/collectivites/${params.collectiviteId}/ticket/${ticket.id}`
                    : "/ticket/" + ticket.id;

                lis.push(
                    <li key={ticket.id}>
                        <section className="identification-ticket">
                            <div data-statut-ticket={ticket.statut} className="statut-ticket">{ticket.statut}</div>
                            <div className="titre-ticket">
                                {
                                    ticket.categorie !== null
                                        ? <span>{ticket.categorie}&nbsp;: </span>
                                        : null
                                }
                                <span dangerouslySetInnerHTML={{__html:ticket.titre}}></span>
                            </div>
                            <div className="entite-ticket">{entite}</div>
                        </section>
                        <section className="etat-ticket">
                            <div className="id-ticket">#{ticket.id}</div>
                            <div className="date-ticket">Ouvert le {dateOuverture}</div>
                            <div className="date-ticket"><abbr title="Derniere mise à jour">MAJ</abbr> le {dateModification}</div>
                            <Link to={ticketLink} className="bouton-details-ticket">Voir</Link>
                        </section>
                    </li>
                )
            }
        });
    }

    return (
        <>
            <section className={"section-contenu-page page-tickets" + (props.vueAdmin ? " page-tickets-admin" : "") }>
                <div className="page-top-section-header">
                    <h1 className="titre-page">
                        <div>
                            <FontAwesomeIcon icon={solid("ticket")} size="sm" className="icone-titre-page"/>
                            {
                            !props.vueAdmin
                                ? "Vos demandes d'assistance"
                                : "Demandes d'assistance" + (collectiviteData !== FAILED ? " - " + collectiviteData.nom : "")
                            }
                        </div>
                    </h1>
                    {
                        !props.vueAdmin
                            ? <Link to={"/ticket/creation"} className="lien-creation-ticket">Demander une assistance</Link>
                            : null
                    }
                    {
                        tickets === FAILED
                            ? null
                            : (
                                <>
                                    <form className="filtre-liste-tickets" autoComplete="off">
                                        <label className="form-search-input-label">
                                            <FontAwesomeIcon icon={solid("magnifying-glass")} size="lg" className="icone-recherche" />
                                            <Input
                                                type="texte"
                                                name="texte-filter"
                                                placeholder="Rechercher une demande d'assistance"
                                                onChange={onFilterInputTextChange}
                                            ></Input>
                                        </label>
                                        <section className="checkbox-filter">
                                            <label className="form-check-label">
                                                <Input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    value={FILTRE_STATUTS.nouveau}
                                                    checked={state.filtreStatuts.includes(FILTRE_STATUTS.nouveau)}
                                                    onChange={onFilterCheckboxChange}
                                                ></Input>
                                                Nouveau
                                            </label>
                                            <label className="form-check-label">
                                                <Input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    value={FILTRE_STATUTS.en_cours}
                                                    checked={state.filtreStatuts.includes(FILTRE_STATUTS.en_cours)}
                                                    onChange={onFilterCheckboxChange}
                                                ></Input>
                                                En cours
                                            </label>
                                            <label className="form-check-label">
                                                <Input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    value={FILTRE_STATUTS.en_attente}
                                                    checked={state.filtreStatuts.includes(FILTRE_STATUTS.en_attente)}
                                                    onChange={onFilterCheckboxChange}
                                                ></Input>
                                                En attente
                                            </label>
                                            <label className="form-check-label">
                                                <Input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    value={FILTRE_STATUTS.resolu}
                                                    checked={state.filtreStatuts.includes(FILTRE_STATUTS.resolu)}
                                                    onChange={onFilterCheckboxChange}
                                                ></Input>
                                                Résolu
                                            </label>
                                            <label className="form-check-label">
                                                <Input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    value={FILTRE_STATUTS.clos}
                                                    checked={state.filtreStatuts.includes(FILTRE_STATUTS.clos)}
                                                    onChange={onFilterCheckboxChange}
                                                ></Input>
                                                Clos
                                            </label>
                                        </section>
                                    </form>
                                </>
                            )
                    }
                </div>
                {
                    tickets === FAILED
                        ? <LoadFailed/>
                        : (
                            <ul className="liste-tickets">
                                {
                                    tickets.length !== 0 && lis.length === 0
                                        ? <p className="empty">Aucun ticket ne correspond à votre recherche</p>
                                        : lis
                                }
                            </ul>
                        )
                }
            </section>
        </>
    );
}

/**
 * Retourne true si les mots du champs de recherche sont dans le texte du ticket.
 *
 * @param {Array<String>} champsTicket: Les différents champs du tickets dans lesquels on veut chercher
 * @param {String} search: La valeur du champ de recherche
 * @returns Boolean
 */
function matchTexteTicket(champsTicket, search) {
    // On récupère chaque mot de la recherche
    const words = search.split(" ")

    // On prend les différents champs du tickets et on les concatène en une seule
    // chaine de caractère pour pouvoir effectuer une recherche sur plusieurs champs
    // à la fois (par ex: "Mairie Pouzin 2024 resiliation")
    const string = champsTicket.join(" ");

    // Finalement, on veut que chaque mot recherché apparaisse dans le ticket
    return words.every(function(word) {
        return localeIncludes(string, word);
    })
}

export default Tickets;