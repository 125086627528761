import React from "react";
import { useContext } from "react";
import { CurrentUserContext } from "../../context/CurrentUserContext";

export default function EmptyAbonnementListeMessage(props) {
    const { currentUser } = useContext(CurrentUserContext);

    return (
        <div className={props.class}>
            <p>Vous n'avez aucun contrat.</p>
            <p>Veuillez {
                    currentUser.menus.ticket
                        ? <><a href="/ticket/creation">demander une assistance</a> ou </>
                        : ""
                } <a href="/formulaire_contact">nous contacter</a> si vous avez un contrat et que celui-ci n'apparaît pas sur votre compte.
            </p>
            <p>Découvrir <a href="/services">tous nos services</a></p>
        </div>
    );
}