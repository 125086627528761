import React from "react";

export default function DonneesPersonnelles() {

    return (
        <section className="section-contenu-page page-statique">
            <h1 className="titre-page">Données personnelles</h1>
            <section>
                <p>
                    La présente politique de confidentialité sur le respect de la vie privée a pour objectif de formaliser notre engagement quant
                    au respect de la vie privée des utilisateurs du portail MYNUMERIAN.
                </p>
                <p className="texte-gras">
                    La politique de confidentialité et les conditions générales d’utilisation forment un ensemble contractuel.
                </p>
                <p>
                    Dans le cadre de la mise à disposition de notre Portail, nous traitons vos données à caractère personnel dans le respect du règlement
                    Général sur la Protection des Données 2016/679 du 27 avril 2016 (« RGPD ») et dans les conditions exposées ci-après.
                </p>
                <p>
                    Une donnée à caractère personnel désigne toute information se rapportant à une personne physique identifiée ou identifiable. Nous
                    collectons et traitons des données à caractère personnel dans le cadre de la fourniture de nos Services ou de la communication à
                    propos de ces Services exclusivement, en stricte conformité avec le RGPD.
                </p>
                <p>
                    Nous collectons uniquement des données à caractère personnel adéquates, pertinentes et limitées à ce qui est nécessaire au regard
                    des finalités pour lesquelles elles sont traitées. Ainsi, il ne vous sera jamais demandé de renseigner des données à caractère personnel
                    considérées comme « sensibles », telles que vos origines raciales ou ethniques, vos opinions politiques, philosophiques ou religieuses.
                </p>
                <p>
                    En vous enregistrant sur le Portail, vous nous autorisez à traiter vos données à caractère personnel conformément à la politique de confidentialité.
                    <span className="texte-gras"> Si vous refusez les termes de cette politique de confidentialité, veuillez vous abstenir d’utiliser le portail.</span>
                </p>
            </section>
            <section className="section-article">
                <h2 className="sous-titre-page-statique">1. Dans quels cas collectons-nous vos données à caractère personnel et quelles données sont collectées ?</h2>
                <p>
                    Nous sommes susceptibles de recueillir et de conserver vos données à caractère personnel, notamment lorsque vous :
                </p>
                <ul>
                    <li>Naviguez sur le portail</li>
                    <li>Nous contactez.</li>
                </ul>
                <p>
                    Nous utilisons vos données à caractère personnel pour permettre la mise en œuvre et la gestion des services du Portail et répondre à vos demandes
                    spécifiques. Nous utilisons également vos données à caractère personnel dans le but d’exploiter et d’améliorer nos services, notre portail et notre
                    démarche. Ces informations sont utilisées uniquement par nos soins et nous permettent de mieux adapter nos services à vos attentes.
                </p>
                <p>
                    Vous ne recevrez aucune publicité et promotion que vous n’ayez vous-même demandé.
                </p>
                <section className="sous-section">
                    <h3>1.1 Navigation sur le portail</h3>
                    <p>
                        <span className="texte-gras">Données de connexion. </span>
                        À chaque fois que vous vous connectez à notre Portail, nous recueillons des données à caractère personnel telles que, notamment, votre adresse IP,
                        la date et l’heure de connexion, ainsi que des informations sur le navigateur que vous utilisez.
                    </p>
                </section>
                <section className="sous-section">
                    <h3>1.2 Contacts</h3>
                    <p>
                        Afin de donner suite aux demandes que vous pourriez effectuer auprès de notre service client et de confirmer les informations vous concernant,
                        nous pourrons utiliser vos nom, prénom, adresse de courrier électronique.
                    </p>
                </section>
            </section>
            <section className="section-article">
                <h2 className="sous-titre-page-statique">2. Comment protégeons-nous vos données à caractère personnel ?</h2>
                <p>
                    Nous avons mis en place des mesures de sécurité techniques et organisationnelles en vue de garantir la sécurité, l’intégrité et la confidentialité
                    de toutes vos données à caractère personnel, afin d’empêcher que celles-ci soient déformées, endommagées ou que des tiers non autorisés y aient accès.
                    Nous assurons un niveau de sécurité approprié, compte tenu de l’état des connaissances, des coûts de mise en œuvre et de la nature, de la portée, du
                    contexte et des finalités du traitement ainsi que des risques et de leur probabilité.
                </p>
                <p>
                    Toutefois, il est précisé qu’aucune mesure de sécurité n’étant infaillible, nous ne sommes pas en mesure de garantir une sécurité absolue à vos données
                    à caractère personnel.
                </p>
                <p>
                    Par ailleurs, il vous incombe d’assurer la confidentialité du mot de passe vous permettant d’accéder à votre compte. Ne communiquez cette information à personne.
                    Si vous partagez votre ordinateur, n’oubliez pas de vous déconnecter avant de quitter un service.
                </p>
            </section>
            <section className="section-article">
                <h2 className="sous-titre-page-statique">3. Dans quels cas partageons-nous vos données à caractère personnel ?</h2>
                <section className="sous-section">
                    <h3>3.1 Partage de vos données personnelles avec des sociétés tierces</h3>
                    <p>
                        Nous ne partagerons jamais vos données à caractère personnel avec des sociétés tierces.
                    </p>
                </section>
                <section className="sous-section">
                    <h3>3.2 Partage avec les autorités</h3>
                    <p>
                        Nous pouvons être amenés à divulguer vos données à caractère personnel aux autorités administrative ou judiciaire lorsque leur divulgation est nécessaire à l’identification,
                        l’interpellation ou la poursuite en justice de tout individu susceptible de porter préjudice à nos droits, de tout autre utilisateur ou d’un tiers. Nous pouvons enfin être
                        légalement tenus de divulguer vos données à caractère personnel et ne pouvons dans ce cas nous y opposer.
                    </p>
                </section>
            </section>
            <section className="section-article">
                <h2 className="sous-titre-page-statique">4. Combien de temps conservons-nous vos données à caractère personnel ?</h2>
                <p>
                    Nous ne conserverons vos données à caractère personnel que le temps de votre inscription sur le Portail afin d’assurer votre identification lors de votre connexion à votre compte
                    et de permettre la fourniture des services.
                </p>
                <p>
                    Ainsi, si vous procédez à votre désinscription du Portail, vos données à caractère personnel seront effacées et uniquement conservées sous forme d’archive aux fins d’établissement
                    de la preuve d’un droit ou d’un contrat.
                </p>
                <p>
                    En tout état de cause, nous conserverons vos données à caractère personnel pendant une durée n’excédant pas celle nécessaire au regard des finalités pour lesquelles elles sont
                    traitées conformément aux utilisations exposées dans la présente politique de confidentialité et dans le respect des lois et règlements.
                </p>
            </section>
            <section className="section-article">
                <h2 className="sous-titre-page-statique">5. Cookies : comment les utilisons-nous ?</h2>
                <p>
                    Notre Portail n'utilise pas de cookies.
                </p>
            </section>
            <section className="section-article">
                <h2 className="sous-titre-page-statique">6. Quels sont vos droits ?</h2>
                <p>
                    Vous êtes seuls à nous avoir communiqué les données en notre possession, par l’intermédiaire du Portail. Vous disposez de droits sur vos données à caractère personnel.
                    Conformément à la réglementation en matière de protection des données à caractère personnel, notamment les articles 15 à 22 du RGPD, et après avoir justifié de votre
                    identité, vous avez le droit de nous demander l’accès aux données à caractère personnel vous concernant, la rectification ou l’effacement de celles-ci.
                </p>
                <p>
                    En outre, dans les limites posées par la loi, vous disposez également du droit de vous opposer au traitement, de le limiter, de décider du sort post-mortem de vos données,
                    de retirer votre consentement à tout moment et du droit à la portabilité des données à caractère personnel fournies.
                </p>
                <div className="container-adresse">
                    <p>
                        Vous pouvez contactez nos services afin d’exercer vos droits à l’adresse électronique suivante : <a href="mailto:dpo@numerian.fr">dpo@numerian.fr</a> ou à l’adresse
                        postale suivante :
                    </p>
                    <address> Syndicat Mixte NUMERIAN, DPO - Information confidentielle - Ne pas ouvrir, ZI Rhône Vallée Sud, 07250 LE POUZIN</address>
                    <p>
                        , en joignant à votre demande une copie d’un titre d’identité.
                    </p>
                </div>
            </section>
            <section className="section-article">
                <h2 className="sous-titre-page-statique">7. Pouvons-nous modifier la politique de confidentialité ?</h2>
                <p>
                    Nous nous réservons le droit de modifier la politique de confidentialité à tout moment. Il est vous est donc recommandé de la consulter régulièrement. En cas de modification,
                    nous publierons ces changements sur cette page et aux endroits que nous jugerons appropriés en fonction de l’objet et de l’importance des changements apportés.
                </p>
                <p>
                    Votre utilisation du Portail après toute modification signifie que vous acceptez ces modifications. Si vous n’acceptez pas certaines modifications substantielles apportées à
                    la présente politique de confidentialité, vous devez arrêter d’utiliser le Portail.
                </p>
            </section>
            <section className="section-article">
                <h2 className="sous-titre-page-statique">8. Délégué à la protection des données et contact</h2>
                <div className="container-adresse">
                    <p>
                        Pour toute question concernant vos données à caractère personnel ou si vous souhaitez supprimer votre compte, merci de nous contacter à l’adresse postale suivante :
                    </p>
                    <address> NUMERIAN, DPO - Information confidentielle - Ne pas ouvrir, ZI Rhône Vallée Sud, 07250 LE POUZIN</address>
                    <p>
                        , (en indiquant “Vie Privée – Protection des Données”), ou par email à <a href="mailto:dpo@numerian.fr">dpo@numerian.fr</a>.
                    </p>
                </div>
            </section>
            <section className="section-article">
                <h2 className="sous-titre-page-statique">9. La Commission Nationale de l’Informatique et des Libertés (“CNIL”)</h2>
                <div className="container-adresse">
                    <p>
                        Nous vous rappelons que vous pouvez contacter la CNIL directement sur le Portail internet de la CNIL ou par courrier à l’adresse suivante :
                    </p>
                    <address> Commission Nationale de l’Informatique et des Libertés (CNIL), 3 Place de Fontenoy – TSA 80715, 75334 PARIS CEDEX 07.</address>
                </div>
            </section>
        </section>
    );
}
