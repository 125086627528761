import React, { useState } from "react";
import { useParams } from 'react-router-dom';
import { Link, useNavigate } from "react-router-dom";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PrimaryButton from "../../components/ui/PrimaryButton";
import Input from "../../components/forms/Input";
import "./login.css";

function checkRecover(params, setRecoverChecked, setErrors) {
    fetch(`${PASSWORD_URL}/reset/check/${params.recover}/${params.token}`)
        .then((response) => response.json())
        .then((data) => {
            if(data.length > 0) {
                setRecoverChecked("error");
                setErrors(data);
            } else {
                setRecoverChecked("ok");
            }
        })
    ;
}

function requestReset(data, navigate, setErrors, prefix ) {
    fetch(`${PASSWORD_URL}/reset/update`, {
        method: "POST",
        mode: "cors",
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams(data)
    })
        .then((response) => response.json())
        .then((data) => {
            if(data.success) {
                navigate(`/password/${prefix}/confirm`)
            } else {
                setErrors(data.errors);
            }

        })
        .catch(() => setErrors(["Erreur serveur"]));
}

export default function PasswordReset(props) {
    const [recoverChecked, setRecoverChecked] = useState("todo");
    const [errors, setErrors] = useState([]);
    const params = useParams();
    const navigate = useNavigate();


    if(recoverChecked === "todo") {
        checkRecover(params, setRecoverChecked, setErrors);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const data = new FormData(e.target);
        requestReset(
            {
                "form[recover]": data.get("recover"),
                "form[token]": data.get("token"),
                "form[password_new]": data.get("password_new"),
                "form[password_new_confirm]": data.get("password_new_confirm"),
            },
            navigate,
            setErrors,
            props.prefix
        );
    };

    let recoverQuestion;
    let recoverSubmit;
    switch(props.prefix) {
        case "reset":
            recoverQuestion = "Réinitialiser mon mot de passe";
            recoverSubmit = "Modifier le mot de passe";
            break;
        case "init":
            recoverQuestion = "Créer mon mot de passe pour finaliser la création de mon compte";
            recoverSubmit = "Créer mon mot de passe";
            break;
        default:
            throw new Error("props.prefix : invalid value");
    }

    let form;
    switch(recoverChecked) {
        case "todo":
            form = <div className="chargement-donnees-container"><span className="spinner-loader"></span><br/>Chargement en cours</div>
            break;
        case "error":
            form = <Link className="submit-btn" to="/password/recover/again">Demander un nouveau lien</Link>
            break;
        case "ok":
            form =
                <form
                    className="connection-form"
                    onSubmit={handleSubmit}
                >
                    <div className="input-element">
                        <div className="input-with-icon-container">
                            <span>
                                <FontAwesomeIcon icon={solid("key")} />
                            </span>
                            <Input
                                name="password_new"
                                placeholder="Nouveau mot de passe"
                                type="password"
                                disabled={false}
                                required={true}
                                className="input-with-icon"
                                ariaLabelledby="recover-label"
                            />
                        </div>
                    </div>
                    <div className="input-element">
                        <div className="input-with-icon-container">
                            <span>
                                <FontAwesomeIcon icon={solid("key")} />
                            </span>
                            <Input
                                name="password_new_confirm"
                                placeholder="Confirmer le nouveau mot de passe"
                                type="password"
                                disabled={false}
                                required={true}
                                className="input-with-icon"
                                ariaLabelledby="recover-label"
                            />
                        </div>
                    </div>
                    <input
                        type="hidden"
                        name="recover"
                        value={params.recover}
                    />
                    <input
                        type="hidden"
                        name="token"
                        value={params.token}
                    />
                    <PrimaryButton>{recoverSubmit}</PrimaryButton>
                </form>
            break;
    }

    return (
        <>
            <div id="main-connection">
                <div id="connection-left">
                    <img
                        className="connection-logo"
                        src="/img/logo_mynumerian.svg"
                        alt="logo"
                    />
                    <div>
                        <p id="recover-label" className="recover-question">
                            {recoverQuestion}
                        </p>
                        <p className="instructions-mdp">
                            Le mot de passe doit contenir au moins 16 caractères dont 1 majuscule, 1 minuscule, 1 chiffre
                        </p>
                    </div>

                    { errors.length > 0 ? (
                        <div className="error-msg">
                            {errors.map((error) => (<>{error}<br/></>))}
                        </div>) :  null
                    }
                    {form}
                    <Link className="mdp-oublie-link" to="/">Retour à la page de connexion</Link>
                    <div className="lien-rgpd-container">
                        <Link to="/public/conditions_generales_utilisation" target="_blank" rel="noreferrer">CGU</Link>
                        <Link to="/public/donnees_personnelles" target="_blank" rel="noreferrer">Données Personnelles</Link>
                    </div>
                </div>
                <div id="connection-right"></div>
            </div>
        </>
    );
}
