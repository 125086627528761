import React, { useState } from "react";
import { Link } from "react-router-dom";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PrimaryButton from "../../components/ui/PrimaryButton";
import "./login.css";

export default function FormulaireInscription() {
    const [state, setState] = useState({
        sending: false,
        sent: null,
    });

    const onFormSubmit = async e => {
        setState({
            sending: true,
            sent: null,
         });

        e.preventDefault();
        const formData = new FormData(e.target);
        try {
            const response = await fetch(
                `${PUBAPI_URL}/inscription/form_submit`,
                {
                    method: "POST",
                    mode: "cors",
                    body: formData,
                }
            );
            if (!response.ok) {
                throw new Error();
            }

            const data = await response.json();
            if (data === false) {
                throw new Error();
            }

            setState({
               sent: true,
               sending: false,
            });
        } catch(ex) {
            setState({
                sent: false,
                sending: false,
             });
        }
    }

    let form;
    if(state.sending === true) {
        form = <div className="chargement-donnees-container"><span className="spinner-loader"></span><br/>Envoi en cours</div>
    } else {
        switch(state.sent) {
            case true:
                form = <div className="success-msg">Votre demande a bien été envoyée.<br/>Nous vous répondrons prochainement.</div>
                break;
            case false:
                form = <div className="error-msg">L'envoi de votre demande a échoué.<br/>Veuillez réessayer ultérieurement.</div>
                break;
            default:
                form =
                    <form className="connection-form" onSubmit={onFormSubmit}>
                        <div className="input-element">
                            <label htmlFor="email">Votre email</label>
                            <div className="input-with-icon-container">
                                <span><FontAwesomeIcon icon={solid("envelope")} /></span>
                                <input id="email" name="email" type="email" className="input-with-icon" placeholder="saisissez votre email" required disabled={state.sending ? true : undefined}/>
                                </div>
                        </div>
                        <div className="input-element">
                            <label htmlFor="nom">Votre nom</label>
                            <div className="input-with-icon-container">
                                <span><FontAwesomeIcon icon={solid("user")} /></span>
                                <input id="nom" name="nom" className="input-with-icon" placeholder="saisissez votre nom" required disabled={state.sending ? true : undefined}/>
                            </div>
                        </div>
                        <div className="input-element">
                            <label htmlFor="collectivite">Votre collectivité</label>
                            <div className="input-with-icon-container">
                                <span><FontAwesomeIcon icon={solid("city")} /></span>
                                <input id="collectivite" name="collectivite" className="input-with-icon" placeholder="saisissez le nom de votre collectivité" required disabled={state.sending ? true : undefined}/>
                            </div>
                        </div>
                        <PrimaryButton>Envoyer</PrimaryButton>
                    </form>
                ;
        }
    }

    return (
        <>
            <div id="main-connection">
                <div id="connection-left">
                    <img
                        className="connection-logo"
                        src="/img/logo_mynumerian.svg"
                        alt="logo"
                    />
                    <p className="instruction-inscription">
                        Vous n'avez pas de compte MyNumérian ?
                        Vous pouvez demander à le créer en renseignant vos informations dans le formulaire ci-dessous.
                        Vous recevrez un mail d'invitation dès que votre demande aura été validée.
                    </p>

                    {form}

                    <Link className="mdp-oublie-link" to="/">Retour à la page de connexion</Link>
                    <div className="lien-rgpd-container">
                        <Link to="/public/conditions_generales_utilisation" target="_blank" rel="noreferrer">CGU</Link>
                        <Link to="/public/donnees_personnelles" target="_blank" rel="noreferrer">Données Personnelles</Link>
                    </div>
                </div>
                <div id="connection-right"></div>
            </div>

        </>
    );
}
