import React, { useContext, useState } from "react";
import { useLoaderData, useNavigate} from "react-router-dom";
import useToken from "../../hooks/useToken";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import "./informationsCollectivite.css";
import Input from "../../components/forms/Input";
import LoadFailed from "../../components/ui/LoadFailed";
import PrimaryButton from "../../components/ui/PrimaryButton";
import { CurrentUserContext } from "../../context/CurrentUserContext";
import { safeJsonFetch, FAILED, safeFetch } from "../../utils";

export async function formCollectiviteLoader(token) {
    let data = {};
    data.form = await safeJsonFetch(
        `${API_URL}/collectivite/form`,
        token
    );

    data.collectivite = await safeJsonFetch(
        `${API_URL}/collectivite`,
        token
    )
    return data;
}

function InformationsCollectivite() {

    const initialFormData = useLoaderData();
    const navigate = useNavigate();
    // Dans initialFormData, les names des fields ne sont pas préfixés
    // avec le name du formulaire. On a besoin du préfixe pour que symfony
    // traite bien la soumission du formulaire.
    const getInputName  = (name) => `${initialFormData.form.name}[${name}]`;
    const getErrorForInput = (name) => {
        return state.errors
            .filter(error => error.field === name)
            .map((error, i) => (
                <div key={i} className="error-msg">{error.message}</div>
            ))
    }

    const [state, setState] = useState({
        openForm: false,
        fields: Object.fromEntries(
            Object.entries(initialFormData.form.fields)
                .map(entry => [getInputName(entry[0]), entry[1].data])
        ),
        errorSubmit: false,
        errors: [],
    });

    const { token } = useToken();
    const { currentUser, setCurrentUserAndPersist } = useContext(CurrentUserContext);

    const onInputChange = (event) => {
        const input = event.target;
		setState({
            ...state,
            fields: {
                ...state.fields,
                [input.name]: input.value,
            }
        });
	};

    const onFormSubmit = async e => {
        setState({
            ...state,
            openForm: false,
            errorSubmit: false,
            errors: [],
        });

        e.preventDefault();
        const formData = new FormData(e.target);
        try {
            const response = await safeFetch(
                `${API_URL}/collectivite/update`,
                token,
                {
                    method: "POST",
                    mode: "cors",
                    body: formData,
                }
            );

            if (response === FAILED) {
                throw new Error();
            }

            const data = await response.json();
            if(data.success === false) {
                setState({
                    ...state,
                    openForm: true,
                    errorSubmit: true,
                    errors: data.errors,
                });
                return;
            }



            const nouveauNomCollectivite = formData.get(getInputName('nom'));
            if(currentUser.collectivite !== nouveauNomCollectivite ) {
                setCurrentUserAndPersist({
                    ...currentUser,
                    collectivite: nouveauNomCollectivite,
                })
            }
        } catch(ex) {
            setState({
                ...state,
                openForm: true,
                errorSubmit: true,
            });
        }
    }

    const inputClassName = state.openForm ? "normal-input" : "display-only-input";

    return (
        <>
            <section className="section-contenu-page">
                <h1 className="titre-page titre-avec-h2">
                    <FontAwesomeIcon
                        icon={solid("info")}
                        size="sm"
                        className="icone-titre-page"
                    />
                    Informations de la collectivité
                </h1>
                {
                    initialFormData === FAILED
                        ? <LoadFailed/>
                        : (
                            <>
                            <h2>{initialFormData.collectivite.nom}</h2>
                            <span className="statut-adherent" data-statut-adherent={initialFormData.collectivite.statut_adhesion}>
                                <FontAwesomeIcon
                                    icon={solid("award")}
                                    size="lg"
                                />
                                {initialFormData.collectivite.statut_adhesion === "adherent" ? "Collectivité adhérente" : "Collectivité non adhérente"}
                            </span>
                            {
                                // Il y a eu une erreur, mais aucun message (par exemple: perte de connexion)
                                // donc on affiche un message par défaut
                                state.errorSubmit && state.errors.length === 0
                                    ? <div className="error-msg error-msg-collectivite">Problème de serveur, veuillez réessayer</div>
                                    : null
                            }
                            <form className="formulaire-collectivite" onSubmit={onFormSubmit}>
                                <div className="item-container">
                                    <FontAwesomeIcon
                                        icon={solid("building-columns")}
                                        size="sm"
                                        className="block-icon"
                                    />
                                    <div className="input-container">
                                        <label htmlFor={'nom'}>Nom de la collectivité</label>
                                        {getErrorForInput('nom')}
                                        <Input
                                            className= {inputClassName}
                                            value={state.fields[getInputName('nom')]}
                                            type={initialFormData.form.fields.nom.type}
                                            name={getInputName('nom')}
                                            id={'nom'}
                                            disabled={state.openForm ? initialFormData.form.fields.nom.disabled : true}
                                            required={initialFormData.form.fields.nom.required}
                                            onChange={onInputChange}
                                        />
                                    </div>
                                </div>
                                <div className="item-container">
                                    <FontAwesomeIcon
                                        icon={solid("at")}
                                        size="sm"
                                        className="block-icon"
                                    />
                                    <div className="input-container">
                                        <label htmlFor={'email'}>Adresse email</label>
                                        {getErrorForInput('email')}
                                        <Input
                                            className= {inputClassName}
                                            value={state.fields[getInputName('email')]}
                                            type={initialFormData.form.fields.email.type}
                                            name={getInputName('email')}
                                            id={'email'}
                                            disabled={state.openForm ? initialFormData.form.fields.email.disabled : true}
                                            required={initialFormData.form.fields.email.required}
                                            onChange={onInputChange}
                                        />
                                    </div>
                                </div>
                                <div className="item-container">
                                    <FontAwesomeIcon
                                        icon={solid("phone")}
                                        size="sm"
                                        className="block-icon"
                                    />
                                    <div className="input-container">
                                        <label htmlFor={'tel_fix'}>Téléphone fixe</label>
                                        {getErrorForInput('tel_fix')}
                                        <Input
                                            className= {inputClassName}
                                            value={state.fields[getInputName('tel_fix')]}
                                            type={initialFormData.form.fields.tel_fix.type}
                                            name={getInputName('tel_fix')}
                                            id={'tel_fix'}
                                            disabled={state.openForm ? initialFormData.form.fields.tel_fix.disabled : true}
                                            required={initialFormData.form.fields.tel_fix.required}
                                            onChange={onInputChange}
                                            pattern={initialFormData.form.fields.tel_fix.attr.pattern}
                                            title={initialFormData.form.fields.tel_fix.attr.title}
                                        />
                                    </div>
                                </div>
                                <div className="item-container">
                                    <FontAwesomeIcon
                                        icon={solid("mobile-screen")}
                                        size="sm"
                                        className="block-icon"
                                    />
                                    <div className="input-container">
                                        <label htmlFor={'tel_mob'}>Téléphone Portable</label>
                                        {getErrorForInput('tel_mob')}
                                        <Input
                                            className= {inputClassName}
                                            value={state.fields[getInputName('tel_mob')]}
                                            type={initialFormData.form.fields.tel_mob.type}
                                            name={getInputName('tel_mob')}
                                            id={'tel_mob'}
                                            disabled={state.openForm ? initialFormData.form.fields.tel_mob.disabled : true}
                                            required={initialFormData.form.fields.tel_mob.required}
                                            onChange={onInputChange}
                                            pattern={initialFormData.form.fields.tel_mob.attr.pattern}
                                            title={initialFormData.form.fields.tel_mob.attr.title}
                                        />
                                    </div>
                                </div>
                                <div className="item-container">
                                    <FontAwesomeIcon
                                        icon={solid("location-dot")}
                                        size="sm"
                                        className="block-icon"
                                    />
                                    <div className="input-container">
                                        <label htmlFor={'adresse'}>Adresse</label>
                                        <Input
                                            className= {inputClassName}
                                            value={state.fields[getInputName('adresse')]}
                                            type={initialFormData.form.fields.adresse.type}
                                            name={getInputName('adresse')}
                                            id={'adresse'}
                                            disabled={state.openForm ? initialFormData.form.fields.adresse.disabled : true}
                                            required={initialFormData.form.fields.adresse.required}
                                            onChange={onInputChange}
                                        />
                                        <div className="input-adresse-container">
                                            <div className="input-container">
                                                <label htmlFor={'code_postal'}>Code postal</label>
                                                {getErrorForInput('code_postal')}
                                                <Input
                                                    className= { "input-cp " + inputClassName}
                                                    value={state.fields[getInputName('code_postal')]}
                                                    type={initialFormData.form.fields.code_postal.type}
                                                    name={getInputName('code_postal')}
                                                    id={'code_postal'}
                                                    disabled={state.openForm ? initialFormData.form.fields.code_postal.disabled : true}
                                                    required={initialFormData.form.fields.code_postal.required}
                                                    onChange={onInputChange}
                                                    pattern={initialFormData.form.fields.code_postal.attr.pattern}
                                                    title={initialFormData.form.fields.code_postal.attr.title}
                                                    inputMode={initialFormData.form.fields.code_postal.attr.inputmode}
                                                />
                                            </div>
                                            <div className="input-container">
                                                <label htmlFor={'commune'}>Commune</label>
                                                <Input
                                                    className= {inputClassName}
                                                    value={state.fields[getInputName('commune')]}
                                                    type={initialFormData.form.fields.commune.type}
                                                    name={getInputName('commune')}
                                                    id={'commune'}
                                                    disabled={state.openForm ? initialFormData.form.fields.commune.disabled : true}
                                                    required={initialFormData.form.fields.commune.required}
                                                    onChange={onInputChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="item-container">
                                    <FontAwesomeIcon
                                        icon={solid("users")}
                                        size="sm"
                                        className="block-icon"
                                    />
                                    <div className="input-population-container">
                                        <div className="input-container">
                                            <label htmlFor={'nombre_habitants'}>Nombre d'habitants</label>
                                            {getErrorForInput('nombre_habitants')}
                                            <Input
                                                className= {inputClassName}
                                                value={state.fields[getInputName('nombre_habitants')]}
                                                type={initialFormData.form.fields.nombre_habitants.type}
                                                name={getInputName('nombre_habitants')}
                                                id={'nombre_habitants'}
                                                disabled={state.openForm ? initialFormData.form.fields.nombre_habitants.disabled : true}
                                                required={initialFormData.form.fields.nombre_habitants.required}
                                                onChange={onInputChange}
                                                pattern={initialFormData.form.fields.nombre_habitants.attr.pattern}
                                                title={initialFormData.form.fields.nombre_habitants.attr.title}
                                                inputMode={initialFormData.form.fields.nombre_habitants.attr.inputmode}
                                            />
                                        </div>
                                        <div className="input-container">
                                            <label htmlFor={'nombre_etp'}>Nombre d'<abbr title="Equivalent Temps Plein">ETP</abbr></label>
                                            {getErrorForInput('nombre_etp')}
                                            <Input
                                                className= {inputClassName}
                                                value={state.fields[getInputName('nombre_etp')]}
                                                type={initialFormData.form.fields.nombre_etp.type}
                                                name={getInputName('nombre_etp')}
                                                id={'nombre_etp'}
                                                disabled={state.openForm ? initialFormData.form.fields.nombre_etp.disabled : true}
                                                required={initialFormData.form.fields.nombre_etp.required}
                                                onChange={onInputChange}
                                                pattern={initialFormData.form.fields.nombre_etp.attr.pattern}
                                                title={initialFormData.form.fields.nombre_etp.attr.title}
                                                inputMode={initialFormData.form.fields.nombre_etp.attr.inputmode}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {state.openForm === true ? (
                                    <div className="bouton-action">
                                        <PrimaryButton>Valider</PrimaryButton>
                                        <button
                                            type="button"
                                            className="secondary-btn"
                                            // on utilise navigate afin de 'rafraichir la page' et avoir les dernieres
                                            // données enregistrées lorsque l'on clique sur le bouton annuler
                                            onClick={() => navigate('/informations-collectivite')}>
                                            Annuler
                                        </button>
                                    </div>
                                ) : (
                                    <button
                                        type="button"
                                        className="secondary-btn bouton-action"
                                        onClick={() => setState({
                                            ...state,
                                            openForm:true
                                        })}>
                                        Modifier
                                    </button>
                                )}
                            </form>
                            </>
                        )
                }
            </section>
        </>
    );
}

export default InformationsCollectivite;
