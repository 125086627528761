import React, { createElement, Fragment, useEffect, useRef, useState } from "react";
import { createRoot } from "react-dom/client";
import { useLoaderData, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { autocomplete } from '@algolia/autocomplete-js';
import '@algolia/autocomplete-theme-classic';
import useToken from "../../hooks/useToken";
import { safeJsonFetch, FAILED, replaceUrlWithLink, localeIncludes } from "../../utils";
import BadgeRenouvellementAbonnement from "../../components/ui/BadgeRenouvellementAbonnement";
import EmptyAbonnementListeMessage from "../../components/ui/EmptyAbonnementListeMessage";
import LoadFailed from "../../components/ui/LoadFailed";
import PrixHT from "../../components/ui/PrixHT";
import { useContext } from "react";
import { CurrentUserContext } from "../../context/CurrentUserContext";
import VoletAdminStats from "./voletAdminStats";

import "./home.css";

export const COLLECTIVITE_SEARCH_PARAM = "collectivite_id";

export async function homeLoader(token, currentUser) {
    let result = {};
    result.derniersAbonnements = await safeJsonFetch(
        `${API_URL}/abonnements?${new URLSearchParams({limit: 3, date_debut_sort: "DESC"})}`,
        token
    );

    result.infosCom = await safeJsonFetch(
        `${API_URL}/infocom`,
        token
    );

    if (currentUser.menus.statistiques){
        result.stats = await safeJsonFetch(
            `${API_URL}/stats`,
            token
        );
    }

    if (currentUser.menus.data_collectivites){
        result.collectivites = await safeJsonFetch(
            `${API_URL}/admin/collectivites`,
            token
        );
    }

    return result;
}

const LABELS = {
    DATE_DEBUT: "Date de début",
    DATE_FIN: "Date de fin",
    PRIX: "Prix",
}

const DATE_FORMAT_OPTIONS = {
    day: "2-digit",
    month: "2-digit",
    year: "2-digit"
}

function Home() {

    const { token } = useToken();

    const [state, setState] = useState({
        voletAffiche:false,
        titreVolet: null,
        dataVolet: null,
        representationVolet:null,
        couleurVolet:null,
        idRepresentation:null,
        dataCollectivite: null,
    });

    function onDataCollectiviteCloseButtonClick() {
        setState({
            ...state,
            dataCollectivite: null,
        })
    }

    function onDivStatClick(titre, data, representation, couleur, id) {
        setState({
            ...state,
            voletAffiche:true,
            titreVolet: titre,
            dataVolet: data,
            representationVolet: representation,
            couleurVolet: couleur,
            idRepresentation: id,
        })
    }

    function onVoletCloseButtonClick() {
        setState({
            ...state,
            voletAffiche:false,
            titreVolet: null,
            dataVolet: null,
            representationVolet: null,
            couleurVolet:null,
            idRepresentation:null,
        })
    }

    function onDataCollectiviteDetailsLinkClick (id) {
        // On stocke l'id de la collectivité pour pouvoir effectuer la recherche
        // à nouveau quand l'utilisateur revient sur la Home.
        const params = new URLSearchParams(document.location.search);
        params.set(COLLECTIVITE_SEARCH_PARAM, id);
        window.history.replaceState(
            null,
            "",
            `${document.location.pathname}?${params}`
        );
    }

    async function onCollectiviteSelect(dataCollectivite) {
        // on supprime les parametres de recherche de l'url pour eviter de relancer la recherche si on
        // revient sur la page d'accueil depuis une page qui n'a rien à voir avec les données de la recherche
        window.history.replaceState(
            null,
            "",
            `${document.location.pathname}`
        );

        // Affiche les infos de la collectivite qu'on a directement
        setState({
            ...state,
            dataCollectivite,
            loading:true
        })

        //On récupère les infos supplementaires (tickets, …)
        const idMynumerian = dataCollectivite.infosGenerales.idMynumerian;
        const [tickets, abonnements, comptes] = await Promise.all([
            safeJsonFetch(`${API_URL}/admin/collectivite/${idMynumerian}/tickets?${new URLSearchParams({limit: 3, sort_desc: true, stats: true})}`, token),
            safeJsonFetch(`${API_URL}/admin/collectivite/${idMynumerian}/abonnements?${new URLSearchParams({limit: 3, sort_desc: true, stats: true})}`, token),
            safeJsonFetch(`${API_URL}/admin/collectivite/${idMynumerian}/comptes`, token)
        ]);
        setState({
            ...state,
            dataCollectivite: {
                ...dataCollectivite,
                tickets: tickets,
                abonnements : abonnements,
                comptes : comptes
            },
            loading:false
        })
    }

    useEffect(() => {
        // On gère le retour vers la page d'accueil, lorsque l'utilisateur avait navigué vers
        // une autre page depuis les resultats de la recherche collectivité
        let paramsUrl = new URLSearchParams(document.location.search);
        if (!paramsUrl.has(COLLECTIVITE_SEARCH_PARAM)) {
            // Pas de paramètre GET, on ne fait rien
            return;
        }

        // On récupère les infos de la collectivité depuis l'id contenu dans l'URL
        const storedSearchCollectiviteId = paramsUrl.get(COLLECTIVITE_SEARCH_PARAM);
        const collectiviteId = Number(storedSearchCollectiviteId);
        const collectivite = data.collectivites.find(collectivite => collectivite.id === collectiviteId);
        if (!collectivite) {
            console.warn(storedSearchCollectiviteId, "(", collectiviteId, ")", "non trouvé dans la liste des collectivités");
            return;
        }

        // Et on relance le chargement des données pour la collectivité
        onCollectiviteSelect({
            infosGenerales:{
                nom: collectivite.nom,
                siret : collectivite.siret,
                statutAdhesion : collectivite.statutAdhesion,
                idMynumerian: collectivite.id,
                referenceExterne: collectivite.refExterne,
                referenceExterneGlpi: collectivite.refExterneGlpi,
                adresse : collectivite.adresse,
                codePostal : collectivite.codePostal,
                commune : collectivite.commune,
                email: collectivite.email,
                telFix : collectivite.telFix,
                telMob : collectivite.telMob,
                nbHabitants : collectivite.nombreHabitants,
                nbEtp : collectivite.nombreEtp
            }
        });
    },
        // permet de n'effectuer le callback qu'une seule fois, à la création du composant
        []
    );

    const data = useLoaderData();

    const { currentUser } = useContext(CurrentUserContext);

    return (
        <>
            {currentUser.menus.ticket ?
                <Link to={"/ticket/creation"} className="section-hotline">
                        <FontAwesomeIcon icon={solid("headset")} size="lg" className="icone-hotline"/>
                        <span>Votre Hotline</span>
                </Link>
            : null}
            {currentUser.menus.statistiques || currentUser.menus.data_collectivites ?
                <>
                    <section className={
                        "section-admin"
                        + (
                            state.voletAffiche === true
                            ? " volet-detail-stats-visible"
                            : ""
                        )
                    }>
                        <section className="section-admin-container">
                            <h1 className="titre-page">Tableau de Bord Admin</h1>
                            {currentUser.menus.data_collectivites ?
                                renderBlocInfoCollectivite(
                                    data,
                                    state,
                                    onCollectiviteSelect,
                                    onDataCollectiviteCloseButtonClick,
                                    onDataCollectiviteDetailsLinkClick
                                )
                            : null}
                            <h2 className="titre-page">Les infos "com"</h2>
                            { renderBlocInfoCom(data, true) }
                            {currentUser.menus.statistiques ?
                                renderBlocStatistiques(state, data, onDivStatClick)
                            : null }
                        </section>
                        { state.voletAffiche === true
                            ? <VoletAdminStats
                                titre={state.titreVolet}
                                data={state.dataVolet}
                                onCloseButtonClick={onVoletCloseButtonClick}
                                representation={state.representationVolet}
                                couleur={state.couleurVolet}
                                id={state.idRepresentation}
                                />
                            : null
                        }
                    </section>
                </>
            : null}

            {!currentUser.menus.statistiques || !currentUser.menus.data_collectivites ?
                <section className="section-contenu-page page-home">
                    { renderBlocInfoCom(data) }
                    { currentUser.menus.abonnement ? renderBlocAbonnement(data) : null }
                </section>
            : null}
        </>
    );
}

function renderBlocInfoCom(data, admin = false) {
    const { infosCom } = data;

    if (!infosCom || infosCom === FAILED) {
        return null;
    }

    const corps = infosCom.texte
        .split(/[\r\n]+/)
        .map(
            (ligne, i) =>
            <p key={i}>{replaceUrlWithLink(ligne)}</p>
        );

    const cls = "section-infocom-container" +
                    (
                        infosCom.categorie === "alerte"
                        ? " section-infocom-alerte"
                        : infosCom.categorie === "info"
                        ? " section-infocom-info"
                        : " section-infocom-trivia"
                    );

    const categorie =(
        infosCom.categorie === "alerte"
        ? <>
            <FontAwesomeIcon
            icon={solid("triangle-exclamation")} size="xl"/>
            Alerte Numérian
        </>
        : infosCom.categorie === "info"
        ? <>
            <FontAwesomeIcon
            icon={solid("bullhorn")} size="xl"/>
            Numérian vous informe
        </>
        : <>
            <FontAwesomeIcon
            icon={solid("circle-question")} size="xl"/>
            Le saviez-vous?
        </>
    );

    if (admin) {
        return (
            <section className={cls}>
                <h1>{categorie} - {infosCom.titre}</h1>
                { corps }
            </section>
        );
    }

    return (
        <section className={cls}>
            <h1>{categorie}</h1>
            <h2>{infosCom.titre}</h2>
            { corps }
        </section>
    );
}

function renderBlocAbonnement(data) {
    const { derniersAbonnements } = data;

    const lis = [];
    if (derniersAbonnements !== FAILED) {
        derniersAbonnements.forEach(abonnement => {
            lis.push(
                <li key={abonnement.id}>
                    <span data-statut-abonnement={abonnement.statut} className="statut-abonnement">
                        <FontAwesomeIcon icon={solid("file-signature")} size="2xl"/>
                        <div className="texte-statut-abonnement">
                            {abonnement.statut.toUpperCase()}
                        </div>
                    </span>
                    <span className="nom-abonnement">{abonnement.modele_abonnement}</span>
                    <div className="infos-abonnement">
                        <span className="code-abonnement">{abonnement.code}</span>
                        <BadgeRenouvellementAbonnement abonnement={abonnement}/>
                    </div>
                    <div>
                        <span>{LABELS.DATE_DEBUT}:&nbsp;</span>
                        <span className="date-abonnement">{abonnement.date_debut ? new Date(abonnement.date_debut).toLocaleDateString(undefined, DATE_FORMAT_OPTIONS) : ""}</span>
                    </div>
                    <div>
                        <span>{LABELS.DATE_FIN}:&nbsp;</span>
                        <span className="date-abonnement">{abonnement.date_fin ? new Date(abonnement.date_fin).toLocaleDateString(undefined, DATE_FORMAT_OPTIONS) : ""}</span>
                    </div>
                    <div>
                        <span className="prix-abonnnement">{LABELS.PRIX}:&nbsp;</span>
                        <span className="prix-abonnnement"><PrixHT prix={abonnement.prix_total_annuel}/></span>
                    </div>
                </li>
            );
        });
    }

    return (
        <>
            <section className="section-derniers-contrats-container">
                <div className="liste-derniers-contrats-container">
                    <h1 className="titre-page sous-titre-accueil">
                        <FontAwesomeIcon icon={solid("pen-nib")} size="sm" className="icone-titre-page"/>
                        Vos derniers contrats
                    </h1>
                    {
                        derniersAbonnements === FAILED
                            ? <LoadFailed/>
                            : derniersAbonnements.length === 0
                                ? <EmptyAbonnementListeMessage class={'empty-liste-container'}/>
                                : <>
                                    <ul className="liste-derniers-contrats">
                                        {lis}
                                    </ul>
                                    <Link to={"/abonnements"} className="lien-liste-abonnements">Voir tout</Link>
                                </>
                    }
                </div>
                <img src="/img/signature-contrat.png" alt="representation signature contrat"/>
            </section>
        </>
    )
}

function renderBlocStatistiques(state, data, onDivStatClick) {
    const { dataVolet } = state;
    const { stats } = data;
    const {
        collectivites,
        commentaires_ticket_dernier_mois,
        connexions_dernier_mois,
        creations_ticket_dernier_mois
    } = stats;

    const nbCollectivitesAvecComptes = collectivites.avec_comptes.length;
    const nbCollectivitesSansComptesActives = collectivites.sans_comptes_actives.length;
    const nbCollectivitesAvecComptesActives = collectivites.avec_comptes_actives.length;
    const nbConnexionsDernierMois = connexions_dernier_mois.filter(({nom}) => nom !== null).length;
    const nbCreationTicketDernierMois = creations_ticket_dernier_mois.filter(({nom}) => nom !== null).length;
    const nbCommentairesTicketDernierMois = commentaires_ticket_dernier_mois.filter(({nom}) => nom !== null).length

    return (
        <>
            <h2 className="titre-page">Les statistiques</h2>
            <section className="stats-grid-container">
                <button
                    className={"stat-bloc-bleu " + (dataVolet === collectivites.avec_comptes ? "selected" : "")}
                    onClick={() => onDivStatClick("Collectivités ayant au moins un compte",collectivites.avec_comptes, "liste", "bleu")}
                >
                    <span className="chiffre-stats">{nbCollectivitesAvecComptes} </span>collectivité{nbCollectivitesAvecComptes > 1 ? 's' : ''} inscrite{nbCollectivitesAvecComptes > 1 ? 's' : ''}
                </button>
                <button
                    className={"stat-bloc-vert "+ (dataVolet === collectivites.avec_comptes_actives ? "selected" : "")}
                    onClick={() => onDivStatClick("Collectivités ayant au moins un compte activé", collectivites.avec_comptes_actives, "liste", "vert")}
                >
                    <span className="chiffre-stats" title="nombre de collectivités ayant au moins un compte activé">{nbCollectivitesAvecComptesActives} </span>collectivité{nbCollectivitesAvecComptesActives > 1 ? 's' : ''} avec compte activé
                </button>
                <button
                    className={"stat-bloc-rouge "+ (dataVolet === collectivites.sans_comptes_actives ? "selected" : "")}
                    onClick={() => onDivStatClick("Collectivités n'ayant aucun compte activé", collectivites.sans_comptes_actives, "liste", "rouge")}
                >
                    <span className="chiffre-stats">{nbCollectivitesSansComptesActives} </span>collectivité{nbCollectivitesSansComptesActives > 1 ? 's' : ''} sans compte activé
                </button>
                <button
                    className={"stat-bloc-bleu-clair "+ (dataVolet === connexions_dernier_mois ? "selected" : "")}
                    onClick={() => onDivStatClick("Nombre de connexions au cours des 30 derniers jours", connexions_dernier_mois, "graphique", "bleu-clair", "connexions")}
                >
                    <span className="chiffre-stats">{nbConnexionsDernierMois} </span>connexion{nbConnexionsDernierMois > 1 ? 's' : ''} ces 30 derniers jours
                </button>
                <button
                    className={"stat-bloc-jaune "+ (dataVolet === creations_ticket_dernier_mois ? "selected" : "")}
                    onClick={() => onDivStatClick("Nombre de tickets créés au cours des 30 derniers jours",creations_ticket_dernier_mois,"graphique", "jaune", "creations-ticket")}
                >
                    <span className="chiffre-stats">{nbCreationTicketDernierMois} </span>ticket{nbCreationTicketDernierMois > 1 ? 's' : ''} créé{nbCreationTicketDernierMois > 1 ? 's' : ''} ces 30 derniers jours
                </button>
                <button
                    className={"stat-bloc-jaune-clair "+ (dataVolet === commentaires_ticket_dernier_mois ? "selected" : "")}
                    onClick={() => onDivStatClick("Nombre de commentaires ajoutés dans les tickets au cours des 30 derniers jours", commentaires_ticket_dernier_mois, "graphique", "jaune-clair", "commentaires-ticket")}
                >
                    <span className="chiffre-stats">{nbCommentairesTicketDernierMois} </span>commentaire{nbCommentairesTicketDernierMois > 1 ? 's' : ''} ajouté{nbCommentairesTicketDernierMois > 1 ? 's' : ''} ces 30 derniers jours
                </button>
            </section>
        </>
    );
}

function renderBlocInfoCollectivite(
    data,
    state,
    onCollectiviteSelect,
    onDataCollectiviteCloseButtonClick,
    onDataCollectiviteDetailsLinkClick
) {

    return (
        <>
            <h2>Les données par collectivité</h2>
            <section className="data-collectivite-container">
                <label htmlFor="autocomplete-3-input" className="label-autocomplete-collectivite">Rechercher une collectivité</label>
                <Autocomplete
                    openOnFocus={true}
                    getSources={({ query }) => [
                        {
                            sourceId: "collectivites",
                            getItems() {
                                const collectivites = data.collectivites.filter(collectivite =>
                                    matchTexteCollectivite([collectivite.nom, collectivite.siret], query));
                                return collectivites;
                            },
                            templates: {
                                item({ item, components }) {
                                    return <div><div>{item.nom}</div><div className="siret-container">siret {item.siret ? item.siret : "inconnu"}</div></div>;
                                },
                            },
                            onSelect: async function(event) {
                                // On met le nom de la collectivité dans le champ de recherche
                                event.setQuery(event.item.nom)
                                onCollectiviteSelect({
                                    infosGenerales : {
                                        nom: event.item.nom,
                                        siret : event.item.siret,
                                        statutAdhesion : event.item.statutAdhesion,
                                        idMynumerian: event.item.id,
                                        referenceExterne: event.item.refExterne,
                                        referenceExterneGlpi: event.item.refExterneGlpi,
                                        adresse : event.item.adresse,
                                        codePostal : event.item.codePostal,
                                        commune : event.item.commune,
                                        email: event.item.email,
                                        telFix : event.item.telFix,
                                        telMob : event.item.telMob,
                                        nbHabitants : event.item.nombreHabitants,
                                        nbEtp : event.item.nombreEtp
                                    },
                                });
                            },
                        },
                    ]}
                    noResults = {(query) => `Pas de collectivité contenant "${query}"`}
                    detachedMediaQuery= 'none'
                />
                {state.dataCollectivite ?
                    <DataCollectivite
                        state={state}
                        onDataCollectiviteCloseButtonClick={onDataCollectiviteCloseButtonClick}
                        onDataCollectiviteDetailsLinkClick={onDataCollectiviteDetailsLinkClick}/>
                    : null
                }
            </section>
        </>
    );
}

function DataCollectivite({
    state,
    onDataCollectiviteCloseButtonClick,
    onDataCollectiviteDetailsLinkClick
}) {

    const containerRef = useRef(null);
    // On scroll en haut de la section qui contient les résultats de la recherche
    // pour que ceux-ci soient bien visibles directement.
    useEffect(() => {
        if (!containerRef.current) {
            return undefined;
        }
        const isReduced = window.matchMedia(`(prefers-reduced-motion: reduce)`).matches === true;
        containerRef.current.scrollIntoView({ behavior: isReduced ? "instant" : "smooth"});
    });

    const lisTickets = [];
    const elStatsTicket = [];
    if(state.dataCollectivite.tickets) {
        if (state.dataCollectivite.tickets === FAILED) {
            lisTickets.push(<LoadFailed/>)
        } else {
            state.dataCollectivite.tickets.liste.forEach(ticket => {
                const entite = ticket.entite.replace(/^Entité racine > /, "");
                const dateOuverture = new Date(ticket.date_ouverture).toLocaleDateString();
                const dateModification = new Date(ticket.date_modification).toLocaleDateString();

                lisTickets.push(
                    <li key={ticket.id}>
                        <div className="identification-ticket">
                            <div data-ticket-statut={ticket.statut}>{ticket.statut}</div>
                            <span className="id-ticket"> #{ticket.id}</span>
                        </div>
                        <div className="titre-ticket">
                            {
                                ticket.categorie !== null
                                    ? <span>{ticket.categorie}&nbsp;: </span>
                                    : null
                            }
                            <span dangerouslySetInnerHTML={{__html:ticket.titre}}></span>
                        </div>
                        <div className="entite-ticket">{entite}</div>
                        <div className="container-with-details-button">
                            <div className="date-ticket">
                                <span>Ouvert le {dateOuverture}</span>
                                <span><abbr title="Derniere mise à jour">MAJ</abbr> le {dateModification}</span>
                            </div>
                            <Link
                                to={`collectivites/${state.dataCollectivite.infosGenerales.idMynumerian}/ticket/${ticket.id}`}
                                onClick={() => onDataCollectiviteDetailsLinkClick(state.dataCollectivite.infosGenerales.idMynumerian)}
                                className="btn-details"
                            >
                                Détails
                            </Link>
                        </div>
                    </li>
                )
            })
            // On construit les éléments dédiés à l'affichage des stats des tickets
            for(const [key, value] of Object.entries(state.dataCollectivite.tickets.stats || {})) {
                if(key === 'total') {
                    continue;
                }

                elStatsTicket.push(
                    <>
                        <span data-ticket-statut={key}><span className="nombre-ticket">{value}</span></span>
                        <span>{key + (value > 1 && key === 'Nouveau' ? 'x' :  value > 1 && key === 'Résolu' ? 's' : '')}</span>
                    </>
                )
            }
        }
    }

    const lisAbonnement = [];
    const elStatsAbonnement = [];
    if(state.dataCollectivite.abonnements) {
        if (state.dataCollectivite.abonnements === FAILED) {
            lisAbonnement.push(<LoadFailed/>)
        } else {
            state.dataCollectivite.abonnements.liste.forEach(abonnement => {
                lisAbonnement.push(
                    <li key={abonnement.id}>
                        <div>
                            <span data-abonnement-statut={abonnement.statut}>{abonnement.statut}</span>
                            <span className="code-abonnement">{abonnement.code}</span>
                        </div>
                        <div className="infos-abonnement">
                            <span>{abonnement.modele_abonnement}</span>
                            <BadgeRenouvellementAbonnement abonnement={abonnement}/>
                        </div>
                        <div className="container-with-details-button">
                            <div className="date-abonnement-container">
                                <div>
                                    <span>{LABELS.DATE_DEBUT}:&nbsp;</span>
                                    <span className="date-abonnement">{abonnement.date_debut ? new Date(abonnement.date_debut).toLocaleDateString(undefined, DATE_FORMAT_OPTIONS) : ""}</span>
                                </div>
                                <div>
                                    <span>{LABELS.DATE_FIN}:&nbsp;</span>
                                    <span className="date-abonnement">{abonnement.date_fin ? new Date(abonnement.date_fin).toLocaleDateString(undefined, DATE_FORMAT_OPTIONS) : ""}</span>
                                </div>
                            </div>
                            <Link
                                to={`collectivites/${state.dataCollectivite.infosGenerales.idMynumerian}/abonnement/${abonnement.id}`}
                                onClick={() => onDataCollectiviteDetailsLinkClick(state.dataCollectivite.infosGenerales.idMynumerian)}
                                className="btn-details"
                            >
                                Détails
                            </Link>
                        </div>
                    </li>
                )
            })
            // On construit les éléments dédiés à l'affichage des stats des abonnements
            for(const [key, value] of Object.entries(state.dataCollectivite.abonnements.stats || {})) {
                if(key === 'total') {
                    continue;
                }

                elStatsAbonnement.push(
                    <>
                        <span data-abonnement-statut={key}><span className="nombre-abonnement">{value}</span></span>
                        <span>{key + (value > 1 && key === 'Terminé' ? 's' : '')}</span>
                    </>
                )
            }
        }
    }

    const lisComptes = [];
    if(state.dataCollectivite.comptes) {
        if (state.dataCollectivite.comptes === FAILED) {
            lisComptes.push(<LoadFailed/>)
        } else {
            // On ne veut que les 3 derniers comptes créés
            // pour etre cohérent avec l'affichage des derniers tickets/abonnements
            const comptes = state.dataCollectivite.comptes.filter(({need_invite, have_pwd}) => need_invite === false || have_pwd === true)
                .sort((a, b) => a.id < b.id ? 1 : -1)
                .slice(0,3);

            comptes.forEach(compte => {
                let statutCompte;
                if(compte.have_pwd === true) {
                    statutCompte = "Activé"
                } else if (compte.need_invite === false) {
                    statutCompte = "Non activé"
                }

                lisComptes.push(
                    <li key={compte.id}>
                        <div className="identification-compte">
                            <div data-compte-statut={statutCompte}>{statutCompte}</div>
                            <span className="id-compte"> #{compte.id}</span>
                        </div>
                        <div className="nom-compte">
                            {compte.nom}
                        </div>
                        <div className="email-compte">{compte.email}</div>
                    </li>
                )
            })
        }
    }

    const nbComptesTotal =
        state.dataCollectivite?.comptes && state.dataCollectivite.comptes !== FAILED
            ? state.dataCollectivite.comptes.filter(
                  ({ need_invite, have_pwd }) => need_invite === false || have_pwd === true
              ).length
            : null;
    const nbComptesActives =
        state.dataCollectivite?.comptes && state.dataCollectivite.comptes !== FAILED
            ? state.dataCollectivite.comptes.filter(({ have_pwd }) => have_pwd === true).length
            : null;
    const nbComptesInactives =
        state.dataCollectivite?.comptes && state.dataCollectivite.comptes !== FAILED
            ? state.dataCollectivite.comptes.filter(
                  ({ need_invite, have_pwd }) => need_invite === false && have_pwd === false
              ).length
            : null;

    return (
        <>
            <section>
                {state.loading === true
                    ? <div className="chargement-donnees-container"><span className="spinner-loader"></span><br/>Chargement en cours</div>
                    : <div className="data-collectivite-grid-container" ref={containerRef}>
                        <div className="infos-generales-container">
                            <button className="close-button" aria-label="Bouton fermer" onClick={onDataCollectiviteCloseButtonClick}>
                                <FontAwesomeIcon
                                    icon={solid("xmark")}
                                    size="xl"
                                />
                            </button>
                            <div className="infos-generales identite">
                                <span className="nom">{state.dataCollectivite.infosGenerales.nom}</span>
                                <span><span className="label">Siret&nbsp;:</span> {state.dataCollectivite.infosGenerales.siret ? state.dataCollectivite.infosGenerales.siret : '-'}</span>
                                <span
                                    className={
                                        state.dataCollectivite.infosGenerales.statutAdhesion === "adherent"
                                        ? "statut-collectivite-adherent"
                                        : state.dataCollectivite.infosGenerales.statutAdhesion === "non_adherent"
                                        ? "statut-collectivite-non-adherent"
                                        : ""}
                                >
                                    {
                                        state.dataCollectivite.infosGenerales.statutAdhesion === "adherent"
                                        ? "Adhérent"
                                        : state.dataCollectivite.infosGenerales.statutAdhesion === "non_adherent"
                                        ? "Non adhérent"
                                        : ""
                                    }
                                </span>
                                <span>
                                    {state.dataCollectivite.infosGenerales.nbHabitants ? state.dataCollectivite.infosGenerales.nbHabitants : '-'} habitants
                                    <span> / {state.dataCollectivite.infosGenerales.nbEtp ? state.dataCollectivite.infosGenerales.nbEtp : '-'} Etp</span>
                                </span>
                            </div>
                            <div className="infos-generales">
                                <span><FontAwesomeIcon icon={solid("location-dot")} size="xl" className="icone-adresse"/>{state.dataCollectivite.infosGenerales.adresse}</span>
                                <span>{state.dataCollectivite.infosGenerales.codePostal} {state.dataCollectivite.infosGenerales.commune}</span>
                                <span>{state.dataCollectivite.infosGenerales.telFix} {state.dataCollectivite.infosGenerales.telMob && state.dataCollectivite.infosGenerales.telFix ? ' - ' : ''} {state.dataCollectivite.infosGenerales.telMob}</span>
                                <span>{state.dataCollectivite.infosGenerales.email}</span>
                            </div>
                            <div className="infos-generales">
                                <span className="ref-numerian">
                                    <img
                                        src="/img/icone_N_blanc.svg"
                                        alt="icone MyNumerian"
                                        width="20"
                                        height="20"
                                    />
                                    <span className="label">ID MyNumerian&nbsp;:</span>
                                    {state.dataCollectivite.infosGenerales.idMynumerian}
                                </span>
                                <span className="ref-numerian">
                                    <img
                                        src="/img/icone_odoo.png"
                                        alt="icone odoo"
                                        width="20"
                                        height="20"
                                    />
                                    <span className="label">ID ODOO&nbsp;:</span>
                                    {state.dataCollectivite.infosGenerales.referenceExterne ? state.dataCollectivite.infosGenerales.referenceExterne : '-'}
                                </span>
                                <span className="ref-numerian">
                                    <img
                                        src="/img/icone_glpi.png"
                                        alt="icone glpi"
                                        width="20"
                                        height="20"
                                    />
                                    <span className="label">ID GLPI&nbsp;:</span>
                                    {state.dataCollectivite.infosGenerales.referenceExterneGlpi ? state.dataCollectivite.infosGenerales.referenceExterneGlpi : '-'}
                                </span>
                            </div>
                        </div>
                        <div className="data-bloc-jaune">
                            {
                                state.dataCollectivite.tickets === FAILED
                                ? <LoadFailed/>
                                : state.dataCollectivite.tickets.liste.length === 0
                                ? <>
                                    <span>Aucun ticket</span>
                                    <div className="icone-noResults">
                                        <FontAwesomeIcon icon={solid("ban")} size="2xl"/>
                                    </div>
                                </>
                                : <>
                                    <details>
                                        <summary>
                                            <div>
                                                <span>{state.dataCollectivite.tickets.stats.total} </span>
                                                ticket{state.dataCollectivite.tickets.stats.total > 1 ? 's' : ''}
                                            </div>
                                            <Link
                                                to={`collectivites/${state.dataCollectivite.infosGenerales.idMynumerian}/tickets`}
                                                onClick={() => onDataCollectiviteDetailsLinkClick(state.dataCollectivite.infosGenerales.idMynumerian)}
                                                className="btn-view-all"
                                            >
                                                Voir tout
                                            </Link>
                                        </summary>
                                        <section className="repartition-container">
                                            {elStatsTicket}
                                        </section>
                                    </details>
                                    <span className="titre-data-bloc">Les derniers tickets</span>
                                    <ul>
                                        {lisTickets}
                                    </ul>
                                </>
                            }
                        </div>
                        <div className="data-bloc-violet">
                            {
                                state.dataCollectivite.abonnements === FAILED
                                ? <LoadFailed/>
                                : state.dataCollectivite.abonnements.liste.length === 0
                                ? <>
                                    <span>Aucun contrat</span>
                                    <div className="icone-noResults">
                                        <FontAwesomeIcon icon={solid("ban")} size="2xl"/>
                                    </div>
                                </>
                                : <>
                                    <details>
                                        <summary>
                                            <div>
                                                <span>{state.dataCollectivite.abonnements.stats.total} </span>
                                                abonnement{state.dataCollectivite.abonnements.stats.total > 1 ? 's' : ''}
                                            </div>
                                            <Link
                                                to={`collectivites/${state.dataCollectivite.infosGenerales.idMynumerian}/abonnements`}
                                                onClick={() => onDataCollectiviteDetailsLinkClick(state.dataCollectivite.infosGenerales.idMynumerian)}
                                                className="btn-view-all"
                                            >
                                                Voir tout
                                            </Link>
                                        </summary>
                                        <section className="repartition-container">
                                            {elStatsAbonnement}
                                        </section>
                                    </details>
                                    <span className="titre-data-bloc">Les derniers contrats</span>
                                    <ul>
                                        {lisAbonnement}
                                    </ul>
                                </>
                            }
                        </div>
                        <div className={
                            nbComptesTotal === 0
                            ? "data-bloc-rouge"
                            : "data-bloc-bleu"
                        }>
                            {
                                state.dataCollectivite.comptes === FAILED
                                ? <LoadFailed/>
                                : nbComptesTotal === 0
                                ? <>
                                    <span>Aucun compte</span>
                                    <div className="icone-noResults">
                                        <FontAwesomeIcon icon={solid("ban")} size="2xl"/>
                                    </div>
                                </>
                                : <>
                                    <details>
                                        <summary>
                                            <div>
                                                <span>{nbComptesTotal} </span>
                                                compte{nbComptesTotal > 1 ? 's' : ''}
                                            </div>
                                            {
                                                nbComptesTotal > 3
                                                ? (
                                                    <Link
                                                        to={`collectivites/${state.dataCollectivite.infosGenerales.idMynumerian}/comptes`}
                                                        onClick={() => onDataCollectiviteDetailsLinkClick(state.dataCollectivite.infosGenerales.idMynumerian)}
                                                        className="btn-view-all"
                                                        >
                                                            Voir tout
                                                    </Link>
                                                )
                                                : null
                                            }
                                        </summary>
                                        <section className="repartition-container">
                                            {
                                                nbComptesActives > 0
                                                ? <>
                                                    <span data-compte-statut="Activé"><span className="nombre-compte">{nbComptesActives}</span></span>
                                                    <span>Activé{nbComptesActives > 1 ? 's' : ''}</span>
                                                </>
                                                : null
                                            }
                                            {
                                                nbComptesInactives > 0
                                                ? <>
                                                    <span data-compte-statut="Non activé"><span className="nombre-compte">{nbComptesInactives}</span></span>
                                                    <span>Non activé{nbComptesInactives > 1 ? 's' : ''}</span>
                                                </>
                                                : null
                                            }
                                        </section>
                                    </details>
                                    <span className="titre-data-bloc">
                                        Les derniers comptes
                                        {
                                            nbComptesTotal > 0 && nbComptesInactives === nbComptesTotal
                                            ? <span className="alerte-compte">Aucun activé</span>
                                            : nbComptesTotal > 0 && nbComptesActives === nbComptesTotal
                                            ? <span className="alerte-compte">Tous activés</span>
                                            : null
                                        }
                                    </span>
                                    <ul>
                                        {lisComptes}
                                    </ul>
                                </>
                            }
                        </div>
                    </div>
                }
            </section>
        </>
    )
}

// Création du composant Autocomplete
// https://www.algolia.com/doc/ui-libraries/autocomplete/integrations/using-react/#with-react-18
function Autocomplete(props) {
    const containerRef = useRef(null);
    const panelRootRef = useRef(null);
    const rootRef = useRef(null);

    useEffect(() => {
      if (!containerRef.current) {
        return undefined;
      }

      const search = autocomplete({
        container: containerRef.current,
        renderer: { createElement, Fragment, render: () => {} },
        render({ children }, root) {
          if (!panelRootRef.current || rootRef.current !== root) {
            rootRef.current = root;

            panelRootRef.current?.unmount();
            panelRootRef.current = createRoot(root);
          }

          panelRootRef.current.render(children);
        },
        renderNoResults({ state, render }, root) {
            if (!panelRootRef.current || rootRef.current !== root) {
                rootRef.current = root;

                panelRootRef.current?.unmount();
                panelRootRef.current = createRoot(root);
              }

            const noResultChildren = props.noResults ? props.noResults(state.query) : "Aucun résultat";
            panelRootRef.current.render(noResultChildren);
        },
        ...props,
      });

      return () => {
        search.destroy();
      };
    }, [props]);

    return <div ref={containerRef} />;
}

/**
 * Retourne true si les mots saisis dans le champs de recherche sont dans le nom ou le siret de la collectivité.
 *
 * @param {Array<String>} criteresRecherche: le nom et le siret de la collectivité à partir desquels on veut chercher
 * @param {String} search: La valeur du champ de recherche
 * @returns Boolean
 */
function matchTexteCollectivite(criteresRecherche, search) {
    // On récupère chaque mot de la recherche
    const words = search.split(" ")

    // On concatène le nom et le siret de la collectivité en une seule chaine de caractère
    // pour pouvoir effectuer une recherche sur plusieurs critères à la fois (par ex: "Mairie Teil 21070319500011")
    const string = criteresRecherche.join(" ");

    // Finalement, on veut que chaque mot recherché apparaisse dans la collectivité
    return words.every(function(word) {
        return localeIncludes(string, word);
    })
}

export default Home;
