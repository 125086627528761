import React from "react";
import { useLoaderData } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { safeJsonFetch, FAILED } from "../utils";
import LoadFailed from "../components/ui/LoadFailed";
 import "./comptes.css";

export async function comptesLoader({ params },token) {
    let url;
    let collectiviteData;
    if (params?.collectiviteId) {
        collectiviteData = await safeJsonFetch(
            `${API_URL}/admin/collectivite/${params.collectiviteId}`,
           token
        )

        url = `${API_URL}/admin/collectivite/${params.collectiviteId}/comptes`;
    }

    const comptes = await safeJsonFetch(
        url,
       token
    );
    return {
        comptes,
        collectiviteData
    };
}

function Comptes () {
    let {
        comptes,
        collectiviteData,
    } = useLoaderData();
    const lis = [];

    if (comptes !== FAILED) {
        comptes.forEach(compte => {
            let statutCompte;
            if(compte.have_pwd === true) {
                statutCompte = "Activé"
            } else if (compte.need_invite === false) {
                statutCompte = "Non activé"
            }

            lis.push(
                <li key={compte.id}>
                    <div className="identification-compte">
                        <div data-compte-statut={statutCompte}>{statutCompte}</div>
                        <div className="nom-compte">
                            {
                                compte.nom
                                ? compte.nom
                                : "-"
                            }
                        </div>
                        <div>
                            {compte.email}
                        </div>
                    </div>
                    <div className="id-compte">#{compte.id}</div>
                </li>
            )
        });
    }

    return (
        <>
            <section className={"section-contenu-page page-comptes-admin" }>
                <h1 className="titre-page">
                    <div>
                        <FontAwesomeIcon icon={solid("users")} size="sm" className="icone-titre-page"/>
                        Comptes {collectiviteData !== FAILED ? " - " + collectiviteData.nom : ""}
                    </div>
                </h1>
                {
                    comptes === FAILED
                        ? <LoadFailed/>
                        : (
                            <ul className="liste-comptes">
                                {
                                    comptes.length !== 0 && lis.length === 0
                                        ? <p className="empty">Aucun compte pour cette collectivité</p>
                                        : lis
                                }
                            </ul>
                        )
                }
            </section>
        </>
    );
}

export default Comptes;