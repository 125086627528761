export const FAILED = Symbol("FAIL");

/**
 * Fonction utilitaire qui effectue un fetch vers une url donnée, avec un token d'authentification
 * donné.
 *
 * @param {String} url: L'url à appeler
 * @param {String} token: Le token d'authentification
 * @param {Object} fetchOptions: un objet d'options utilisé dans le fetch (par exemple: "body", "method", ...)
 * @returns {Promise} Une Promise qui se résoud avec la réponse si tout est ok.
 *                    Si la réponse n'est pas une 200, ou si il y a une erreur quelconque, la Promise
 *                    se résoud avec le Symbol FAILED
 */
export async function safeFetch(url, token, fetchOptions = {}) {
    try {
        const response = await fetch(
            url,
            {
                headers: {
                    "Authorization": `Bearer ${token}`,
                },
                ...fetchOptions,
            }
        );

        if (response.ok) {
            return response;
        } else {
            // Le token n'est pas/plus valide, on déconnecte l'utilisateur
            if (response.status === 401) {
                document.location = "/logout?clientOnly";
                return;
            }
            throw new Error("Response not ok");
        }
    } catch(e) {
        return FAILED;
    }
};

/**
 * Fonction utilitaire qui effectue un fetch vers une url donnée, avec un token d'authentification
 * donné.
 *
 * @param {String} url: L'url à appeler
 * @param {String} token: Le token d'authentification
 * @returns {Promise} Une Promise qui se résoud avec le JSON de la réponse si tout est ok.
 *                    Si la réponse n'est pas une 200, ou si il y a une erreur quelconque, la Promise
 *                    se résoud avec le Symbol FAILED
 */
export async function safeJsonFetch(url, token) {
    const response = await safeFetch(url, token);
    if (response === FAILED) {
        return FAILED;
    }

    try {
        return response.json();
    } catch(e) {
        return FAILED;
    }
};

/*
 * Renvoie true si la chaine de caractères recherchée est inclus dans la chaine de caractères à laquelle se référer
 * Ex:
 *  - localeIncludes("Problème", "eme") => true
 *  - localeIncludes("Problème", "problEme") => true
 *  - localeIncludes("Problème", "") => true
 *
 * @param {String} string: la chaine de caractères à laquelle se référer
 * @param {String} search: la chaine de caractères recherchée
 */
export function localeIncludes(string, search) {
    if (!string) {
        return false;
    }

    // on retourne true si la chaine recherchée = "" pour etre cohérent avec la méthode includes()
    if(search === "") {
        return true;
    }

    const matchingFirstCharIndeces = [];
    const searchChars = Array.from(search);
    //pour chaque caractère de la chaine de caractère de référence
    Array.from(string).forEach(function(char, i) {
        //si c'est le meme que le 1er caractère de la chaine de caractères recherchée
        if (isSameChar(char, search[0])){
            //on ajoute l'index dans le tableau
            matchingFirstCharIndeces.push(i);
        }
    });

    // si on a pas trouvé de caractère dans string
    if (matchingFirstCharIndeces.length === 0) {
        return false;
    }

    //Pour chaque index
    for (const startIndex of matchingFirstCharIndeces) {
        // Si chaque caractère de la chaine de caractères recherchée a bien une équivalence dans la
        // chaine de caractères de référence à la position attendue.
        const allCharsMatch = searchChars.every(function(char, i) {
            return isSameChar(char, string[startIndex + i]);
        });

        if (allCharsMatch) {
            return true;
        }
    }

    // Pas de correspondance entre les 2 chaines de caractères
    return false;
}

// Objet qui permet de faire des comparaisons entre chaines de caractères en français
// sans se soucier des accents, majuscules...
// https://developer.mozilla.org/fr/docs/Web/JavaScript/Reference/Objets_globaux/Intl/Collator
const collator = new Intl.Collator('fr', { sensitivity: 'base' });

 /*
 * Renvoie true si les 2 caractères passés en paramètres sont equivalents
 * Ex:
 *  - isSameChar("é", "e") => true
 *  - isSameChar("V", "v") => true
 *
 * @param {String} charA
 * @param {String} charB
 */
function isSameChar(charA, charB) {
    return collator.compare(charA, charB) === 0;
}

/**
 * Cherche les urls http/s dans la string  et les remplace par des liens de la forme <a href=url>url</a>
 * Attention, la valeur retournée n'est pas une string, mais un tableau de string et d'éléments JSX
 *
 * @param {String} string: string contenant un nombre arbitraire d'url http/s
 * @returns {Array} Un tableau de string et d'éléments <a>
 */
export function replaceUrlWithLink(string) {
    let urlRegex = /(https?:\/\/[^ ]+)/
    return string
        .split(urlRegex)
        .map(function(string) {
            if(urlRegex.test(string)) {
                return <a href={string}>{string}</a>
            } else {
                return string;
            }
        })
}
