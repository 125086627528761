import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Link, NavLink, useLocation } from "react-router-dom";
import "./menu.css";
import { useContext } from "react";
import { CurrentUserContext } from "../../../../context/CurrentUserContext";

export default function Menu({ handleClick, displaySmallScreenMenu }) {

    const { currentUser } = useContext(CurrentUserContext);

    let location = useLocation();

    return (
        <aside className={displaySmallScreenMenu ? "visible" : null}>
            {/*********** Menu affiché en version mobile ***********/}
            <div id="mobile-menu-top">
                <button
                    id="cross-icon"
                    className="cross-btn"
                    onClick={handleClick}
                    aria-label="Bouton fermer"
                    aria-expanded={false}
                    aria-controls="aside">
                    <FontAwesomeIcon
                        icon={icon({ name: "xmark" })}
                        size="3x"
                        className="xmark-icon"
                    />
                </button>
            </div>
            {/************Bouton affiché en version desktop ***********/}
            <div id="account-info">
                <Link to="/logout" className="logout" aria-label="Déconnexion" title="Déconnexion">
                    <FontAwesomeIcon
                        icon={icon({ name: "power-off" })}
                        size="sm"
                        className="power-off-icon"
                    />
                </Link>
                <span className="username">
                    {currentUser.name ? currentUser.name : "-"}
                </span>
                <Link
                    to="/mon-compte"
                    onClick={handleClick}
                    className="my-account">
                    Mon compte
                </Link>
            </div>
            {/************Informations collectivité ***********/}
            {currentUser.collectivite !== null ? (
                <>
                    <div id="city-info">{currentUser.collectivite}</div>
                    <hr />
                </>
            ) : null}
            {/************Navigation pages ***********/}
            <nav className="aside-nav">
                <ul id="aside-menu-list">
                    <li className="menu-item">
                        {/**Pas possible de créer un composant en passant le nom
                         * de l'icône en props à cause de Babel (qui permet de
                         * n'importer que les icônes utilisées):
                         * Pendant le build, il n'a pas encore les éléments du props,
                         * Il ne sait donc pas quelles icônes importer
                         * A voir si on choisit de créer notre propre librairy:
                         * https://fontawesome.com/docs/web/use-with/react/add-icons
                         */}
                        <NavLink
                            to="/"
                            onClick={handleClick}
                            className={({ isActive }) =>
                                isActive
                                    ? "active-menu-item aside-menu-link"
                                    : "aside-menu-link"
                            }>
                            <FontAwesomeIcon
                                icon={solid("house")}
                                className="rounded-icon"
                            />
                            Accueil
                        </NavLink>
                    </li>
                    {currentUser.menus.abonnement ?
                        <li className="menu-item">
                            <NavLink
                                to="/abonnements"
                                onClick={handleClick}
                                className={({ isActive }) =>
                                    isActive
                                        ? "active-menu-item aside-menu-link"
                                        : "aside-menu-link"
                                }>
                                <FontAwesomeIcon
                                    icon={solid("pen-nib")}
                                    className="rounded-icon"
                                />
                                Contrats
                            </NavLink>
                        </li>
                    : null}
                    {currentUser.menus.collectivite ?
                        <li className="menu-item">
                                <NavLink
                                    to="/informations-collectivite"
                                    onClick={handleClick}
                                    className={({ isActive }) =>
                                        isActive
                                            ? "active-menu-item aside-menu-link"
                                            : "aside-menu-link"
                                    }>
                                    <FontAwesomeIcon
                                        icon={solid("info")}
                                        className="rounded-icon"
                                    />
                                    Informations de la collectivité
                                </NavLink>
                        </li>
                    : null}
                    <li className="menu-item">
                        <NavLink
                            to="/services"
                            onClick={handleClick}
                            className={({ isActive }) =>
                                isActive
                                    ? "active-menu-item aside-menu-link"
                                    : "aside-menu-link"
                            }>
                            <FontAwesomeIcon
                                icon={solid("toolbox")}
                                className="rounded-icon"
                            />
                            Tous nos services
                        </NavLink>
                    </li>
                    {currentUser.menus.ticket ?
                        <li className="menu-item">
                            <NavLink
                                to="/tickets"
                                onClick={handleClick}
                                className={({ isActive }) =>
                                    /* On veut que l'élément apparaisse actif quand l'utilisateur est sur une page relative au ticket (liste, dtétail, formulaire de création) */
                                    isActive || location.pathname.startsWith("/ticket/")
                                        ? "active-menu-item aside-menu-link"
                                        : "aside-menu-link"
                                }>
                                <FontAwesomeIcon
                                    icon={solid("ticket")}
                                    className="rounded-icon"
                                />
                                Assistance
                            </NavLink>
                        </li>
                    : null}
                </ul>
            </nav>
            {/************ mentions légales, CGV ... ***********/}
            <div className="aside-bottom">
                <Link
                    to="/formulaire_contact"
                    onClick={handleClick}
                    className="legal-link">
                    Nous contacter
                </Link>
                <Link
                    to="/mentions_legales"
                    onClick={handleClick}
                    className="legal-link">
                    Mentions légales
                </Link>
                <Link
                    to="/donnees_personnelles"
                    onClick={handleClick}
                    className="legal-link">
                    Données personnelles
                </Link>
                <Link
                    to="/conditions_generales_utilisation"
                    onClick={handleClick}
                    className="legal-link">
                    CGU
                </Link>
                <Link
                    to="/conditions_generales_vente"
                    onClick={handleClick}
                    className="legal-link">
                    CGV
                </Link>
            </div>
        </aside>
    );
}
