import { useContext } from "react";
import { createBrowserRouter, RouterProvider, useNavigation, redirect } from "react-router-dom";
import "./config.css";
import './App.css';
import Root from "./pages/root";
import Home, { homeLoader } from "./pages/home/home";
import Abonnements, { abonnementsLoader } from "./pages/Abonnements";
import Abonnement, { abonnementLoader } from "./pages/Abonnement";
import ModelesAbonnement, { modelesAbonnementLoader } from "./pages/ModelesAbonnement";
import MonCompte, { formUserLoader } from "./pages/moncompte/MonCompte";
import ConditionsGeneralesUtilisation from "./pages/ConditionsGeneralesUtilisation";
import ConditionsGeneralesVente from "./pages/ConditionsGeneralesVente";
import MentionsLegales from "./pages/MentionsLegales";
import DonneesPersonnelles from "./pages/DonneesPersonnelles";
import FormulaireContact from "./pages/FormulaireContact";
import FormulaireInscription from "./pages/login/FormulaireInscription";
import useToken from "./hooks/useToken";
import { safeJsonFetch } from "./utils";
import { CurrentUserContext } from "./context/CurrentUserContext";
import InformationsCollectivite, { formCollectiviteLoader } from "./pages/informationscollectivite/InformationsCollectivite";
import LoggedOutWithHeader from "./components/ui/LoggedOutWithHeader";
import Login from "./pages/login/Login";
import PasswordRecover from "./pages/login/PasswordRecover";
import PasswordConfirm from "./pages/login/PasswordConfirm";
import PasswordReset from "./pages/login/PasswordReset";
import Tickets, { ticketsLoader } from "./pages/Tickets";
import Ticket, { ticketLoader } from "./pages/Ticket";
import FormulaireTicket, {formStructureLoader} from "./pages/FormulaireTicket";
import Erreur403 from "./pages/erreur/Erreur403";
import Erreur404 from "./pages/erreur/Erreur404";
import ErrorBoundary from "./pages/erreur/ErrorBoundary";
import Comptes, {comptesLoader} from "./pages/comptes";

function App() {
    const { token, setToken } = useToken();
    const { currentUser, setCurrentUserAndPersist } = useContext(CurrentUserContext);

    // Composants d’ordre supérieur qui "redirige" vers la page de login
    // lorsqu'il n'y a pas de token de renseigné.
    const SecureRoot = (props) => {
        const navigation = useNavigation();
        if (!token) {
            return <Login setToken={setToken} />
        }

        // Si l'utilisateur est déjà loggé, on render le composant enfant
        return <Root loading={navigation.state === "loading"}>{props.children}</Root>;
    }

    const routes = [
        {
            path: "/",
            element: < Home />,
            loader: () => homeLoader(token, currentUser),
        },
        {
            path: "/abonnements",
            element: currentUser?.menus?.abonnement ?
                <Abonnements /> :
                <Erreur403 />
            ,
            loader: () => abonnementsLoader({}, token)
        },
        {
            path: "/abonnement/:abonnementId",
            element: currentUser?.menus?.abonnement ?
                <Abonnement /> :
                <Erreur403 />
            ,
            loader:(params) => abonnementLoader(params, token)
        },
        {
            path: "/services",
            element: <ModelesAbonnement />,
            loader: () => modelesAbonnementLoader(token)
        },
        {
            path: "/mon-compte/",
            element: <MonCompte />,
            loader: () => formUserLoader(token)
        },
        {
            path: "/informations-collectivite/",
            element: currentUser?.menus?.collectivite ?
                <InformationsCollectivite /> :
                <Erreur403 />
            ,
            loader: () => formCollectiviteLoader(token)
        },
        {
            path: "/tickets/",
            element: currentUser?.menus?.ticket ?
                <Tickets /> :
                <Erreur403 />
            ,
            loader: () => ticketsLoader({},token)
        },
        {
            path: "/ticket/:ticketId",
            element: currentUser?.menus?.ticket ?
                <Ticket /> :
                <Erreur403 />
            ,
            loader: (params) => ticketLoader(params, token)
        },
        {
            path: "/ticket/creation",
            element: currentUser?.menus?.ticket ?
                <FormulaireTicket /> :
                <Erreur403 />
            ,
            loader: () => formStructureLoader(token)
        },
        {
            path: "/conditions_generales_vente",
            element: <ConditionsGeneralesVente />,
        },
        {
            path: "/conditions_generales_utilisation",
            element: <ConditionsGeneralesUtilisation />,
        },
        {
            path: "/mentions_legales",
            element: <MentionsLegales />,
        },
        {
            path: "/donnees_personnelles",
            element: <DonneesPersonnelles />,
        },
        {
            path: "/formulaire_contact",
            element: <FormulaireContact />,
        },
        {
            path: "*",
            element: <Erreur404 />,
        },
        {
            path: "collectivites/:collectiviteId/tickets",
            element: currentUser?.menus?.data_collectivites ?
                <Tickets vueAdmin={true}/> :
                <Erreur403 />
            ,
            loader: (params) => ticketsLoader(params, token)
        },
        {
            path: "collectivites/:collectiviteId/ticket/:ticketId",
            element: currentUser?.menus?.data_collectivites ?
                <Ticket vueAdmin={true}/> :
                <Erreur403 />
            ,
            loader: (params) => ticketLoader(params, token)
        },
        {
            path: "collectivites/:collectiviteId/abonnements",
            element: currentUser?.menus?.data_collectivites ?
                <Abonnements vueAdmin={true} /> :
                <Erreur403 />
            ,
            loader: (params) => abonnementsLoader(params, token)
        },
        {
            path: "collectivites/:collectiviteId/abonnement/:abonnementId",
            element: currentUser?.menus?.data_collectivites ?
                <Abonnement vueAdmin={true}/> :
                <Erreur403 />
            ,
            loader:(params) => abonnementLoader(params, token)
        },
        {
            path: "collectivites_ext/:refExterne/abonnement/:abonnementId",
            element: currentUser?.menus?.data_collectivites ?
                <Abonnement vueAdmin={true}/> :
                <Erreur403 />
            ,
            loader:(params) => abonnementLoader(params, token)
        },
        {
            path: "collectivites/:collectiviteId/comptes",
            element: currentUser?.menus?.data_collectivites ?
                <Comptes /> :
                <Erreur403 />
            ,
            loader: (params) => comptesLoader(params, token)
        },
    ].map(route => ({
        path: route.path,
        element: route.element
            ? <SecureRoot><ErrorBoundary>{route.element}</ErrorBoundary></SecureRoot>
            : null,
        loader : route.loader && token
            ? route.loader
            : null
    }))
    // Les routes suivantes ne demandent pas d'être authentifié
    .concat([
        {
            path: "/logout",
            loader: async () => {
                const params = new URLSearchParams(document.location.search);

                // Si on a un token, on appelle la route de deconnexion du serveur, seulement si
                // il n'y a pas le parametre clientOnly. Ce paramètre est ajouté par safeFetch
                // lorsqu'il recoit une réponse 401, ce qui indique que l'utilisateur est dejà
                // déconnecté (par exemple si le token a expiré).
                if (token && !params.has("clientOnly")){
                    await safeJsonFetch(`${API_URL}/logout`, token);
                }

                // Si on était connecté
                if (token){
                    setCurrentUserAndPersist(
                        null,
                        // empeche le setState, vide seulement les infos de localStorage
                        false
                    );
                    // On passe le token et les infos utilisateurs  à null, ce qui déclenchera
                    // un nouveau render de App, et causera la redirection vers la page de login,
                    // vu qu'on n'a plus de token.
                    setToken(null);
                    return null;
                }

                // Si on était pas connecté, on redirige vers la page d'acceuil directement qui
                // causera la redirection vers la page de login.
                // On ne veut pas faire de setToken(null), car cela causerait des boucles de render infinies.
                return redirect("/");
            },
        },
        {
            path: "/password/recover/:again?",
            element: <PasswordRecover />,
        },
        {
            path: "/password/recover/confirm/:again?",
            element: <PasswordConfirm confirm="recover" />,
        },
        {
            path: "/password/reset/:recover/:token",
            element: <PasswordReset prefix="reset" />,
        },
        {
            path: "/password/reset/confirm",
            element: <PasswordConfirm confirm="reset" />,
        },
        {
            path: "/password/init/:recover/:token",
            element: <PasswordReset prefix="init" />
        },
        {
            path: "/password/init/confirm",
            element: <PasswordConfirm confirm="init" />,
        },
        {
            path: "/public/formulaire_inscription",
            element: <FormulaireInscription />,
        },
        {
            path: "/public/conditions_generales_utilisation",
            element: <LoggedOutWithHeader>
                        <ConditionsGeneralesUtilisation />
                    </LoggedOutWithHeader>,
        },
        {
            path: "/public/donnees_personnelles",
            element: <LoggedOutWithHeader>
                        <DonneesPersonnelles />
                    </LoggedOutWithHeader>,
        },
        {
            path: "/public/services",
            element: <LoggedOutWithHeader>
                        <ModelesAbonnement />
                    </LoggedOutWithHeader>,
            loader: () => modelesAbonnementLoader()
        },
    ].map(route => ({
        ...route,
        element: route.element
            ? <ErrorBoundary isPageLoggedOut={true}>{route.element}</ErrorBoundary>
            : null,
    })));

    const router = createBrowserRouter(routes);

    return (
        <RouterProvider router={router} />
    );
}

export default App;
