import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Bar } from "react-chartjs-2";

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import "./voletAdminStats.css";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export default function VoletAdminStats(props) {
    // Une fois le volet affiché, on scroll en haut de la page pour que ses infos soient visibles.
    useEffect(() => {
        document.body.scrollIntoView();
    });

    return (
        <section className={"section-details-stats bloc-" + props.couleur}>
            <div className="close-button-container">
                <button
                    className={"close-button " + props.couleur}
                    aria-label="Bouton fermer"
                    onClick={props.onCloseButtonClick}
                >
                    <FontAwesomeIcon icon={solid("xmark")} size="xl" />
                </button>
            </div>
            <h1>{props.titre}</h1>
            {props.representation === "liste" ? (
                <ul>
                    {props.data.map((el) => (
                        <li>{el.nom}</li>
                    ))}
                </ul>
            ) : null}
            {props.representation === "graphique" ? (
                <>
                    {props.data ? (
                        <Bar
                            data={{
                                datasets: [{ label: "", data: groupConnectionsByDay(props.data), backgroundColor:'#151828' }],
                            }}
                            id={props.id}
                            aria-label={"histogramme représentant le " + props.titre}
                            options={{
                                plugins: {
                                    legend: { display: false },
                                },
                                scales: {
                                    y: {
                                        beginAtZero: true,
                                        ticks: {
                                            // Pas de decimale sur l'axe ordonné
                                            stepSize: 1,
                                        },
                                        suggestedMax:10,
                                    },
                                    x: {
                                        grid: {
                                            // Pas de ligne verticale pour chaque date
                                            display: false
                                        }
                                    },
                                }
                            }}
                        ></Bar>
                    ) : null}
                </>
            ) : null}
        </section>
    );
}

function groupConnectionsByDay(data) {
    const result = {};
    for (const { date, nom } of data) {
        if (!result[date]) {
            result[date] = {
                x: date,
                y: 0,
            };
        }

        if (nom){
            result[date].y++;
        }
    }
    return Object.values(result);
}

