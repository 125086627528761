import React from "react";

export default function MentionsLegales() {

    return (
        <section className="section-contenu-page page-statique">
            <h1 className="titre-page">Mentions légales</h1>
            <section className="section-article">
                <h2 className="sous-titre-page-statique">Editeur</h2>
                <address>
                    Syndicat Mixte Numérian (Numérian)<br/>
                    2 ZI Rhône Vallée Sud 07250 – LE POUZIN<br/>
                    Téléphone : <a href="tel:+0475301313">04 75 30 13 13</a><br/>
                    Mail : <a href="mailto:contact@numerian.fr">contact@numerian.fr </a><br/>
                    Siret : 82492452600034<br/>
                    Directeur de la publication : Jean-Charles MANRIQUE
                </address>
            </section>
            <section className="section-article">
                <h2 className="sous-titre-page-statique">Hébergement</h2>
                <address>
                    Numérian<br/>
                    2 ZI Rhône Vallée Sud 07250 – LE POUZIN<br/>
                    Mail : <a href="mailto:contact@numerian.fr">contact@numerian.fr </a><br/>
                    Téléphone : <a href="tel:+0475301313">04 75 30 13 13</a>
                </address>
            </section>
            <section className="section-article">
                <h2 className="sous-titre-page-statique">Droit d’auteur</h2>
                <p>
                    La structure générale, les textes et les images contenus dans ce portail sont des créations du Syndicat Mixte NUMERIAN,
                    et sont ainsi protégés par le droit d’auteur. Ces contenus ne peuvent être copiés, utilisés, imités, en tout ou
                    partie, sans l’autorisation expresse préalable du Syndicat Mixte NUMERIAN.
                </p>
                <p>
                    En outre, la charte graphique et le logo sont propriétés de NUMERIAN et ne peuvent être copiés, utilisés, imités,
                    en tout ou partie, sans l’autorisation expresse préalable de NUMERIAN.
                </p>
                <p>
                    Ces éléments sont protégés par un enregistrement INPI.
                </p>
            </section>
        </section>
    );
}
