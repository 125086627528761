import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import "./voletDetailsModeleAbonnement.css";
import useToken from "../hooks/useToken";
import { CurrentUserContext } from "../context/CurrentUserContext"
import { safeJsonFetch, FAILED } from "../utils";
import LoadFailed from "../components/ui/LoadFailed";
import PrixHT from "../components/ui/PrixHT";

export default function VoletDetailsModeleAbonnement(props) {
    const [state, setState] = useState({
        loaded: false,
        loading:false,
        data: null
    });

    const { currentUser } = useContext(CurrentUserContext);

    // Une fois le volet affiché, on scroll en haut de la page pour que ses infos soient visibles.
    useEffect(() => {
        document.body.scrollIntoView();
     });

    const { token } = useToken()

    let url;
    if(props.isDetailAbonnes && token) {
        url = `${API_URL}/clients/modele_abonnement/${props.modeleAbonnementId}`
    } else if(token) {
        url = `${API_URL}/modele_abonnement/${props.modeleAbonnementId}`;
    } else {
        url = `${PUBAPI_URL}/modele_abonnement/${props.modeleAbonnementId}`;
    }

    if(state.loaded === false && state.loading === false) {
        safeJsonFetch(url, token)
        .then((data) => setState({
            loaded: true,
            loading: false,
            data: data
        }));

        setState((prevState => ({
            ...prevState,
            loading: true,
        })));
    }

    if(!props.isDetailAbonnes) {
        return structureVolet(props.onCloseButtonClick, props.modeleAbonnementNom, state, "articles");
    }

    if(props.isDetailAbonnes) {
        return structureVolet(props.onCloseButtonClick, props.modeleAbonnementNom, state, "collectivites" );
    }
}

function structureVolet(onCloseButtonClick, modeleAbonnementNom, state, typeData ) {
    return (
        <section className={"section-details-modele-abonnement " + (typeData === "collectivites" ? "section-details-abonnes" : "")}>
            <div className="close-button-container">
                <button className="close-button" aria-label="Bouton fermer" onClick={onCloseButtonClick}>
                    <FontAwesomeIcon
                        icon={solid("xmark")}
                        size="xl"
                    />
                </button>
            </div>
            <h1>{modeleAbonnementNom}</h1>
            {
                contenuVolet(state, typeData)
            }
        </section>
    );
}

function contenuVolet(state, typeData) {
    if (state.loaded === false) {
        return (
            <div className="chargement-donnees-container">
                <span className="spinner-loader"></span><br/>
                Chargement en cours
            </div>
        );
    }

    if (state.data === FAILED) {
        return <LoadFailed/>;
    }

    if (typeData === "collectivites") {
        const collectivites = state.data;
        const lis = [];

        for( const refExterne in collectivites) {
            const collectivite = collectivites[refExterne];
            const lisAbonnements = [];

            for (const structureId in collectivite.structures) {
                const structure = collectivite.structures[structureId];
                structure.abonnements_souscris.forEach(abonnement => {
                    lisAbonnements.push(
                        <li key={abonnement.id}>
                            <Link to={`/collectivites_ext/${refExterne}/abonnement/${abonnement.id}`} className="code-abonnement" title="voir l'abonnement">
                                {abonnement.code_abonnement}
                            </Link>
                            <span>-</span>
                            <span>{structure.nom}</span>
                        </li>
                    )
                })
            }

            lis.push(
                <li key={refExterne}>
                    <details>
                        <summary>
                            <span>{collectivite.nom}</span>
                        </summary>
                        <ul>{lisAbonnements}</ul>
                    </details>
                </li>
            )
        }

        return (
            <>
                <span className="nombre-abonnes"><span>{Object.keys(collectivites).length}</span>collectivités abonnées</span>
                <ul className="liste-abonnes-modele-abonnement">
                    {lis}
                </ul>
            </>
        );
    }

    if (typeData === "articles") {
        const articles = state.data;
        const lis = [];
        articles.forEach(article => {
            let urlSearchParams;
            let lienAbos = null;
            if (article.souscris) {
                // Quand il n'y a qu'un seul abonnement souscrit pour un article, on renvoie vers la page dédiée à cet abonnement
                if (article.abonnement_souscrit_ids.length === 1) {
                    lienAbos = <Link to={"/abonnement/" + article.abonnement_souscrit_ids }><span className="souscris" title="Voir l'abonnement">✔ Souscrit</span></Link>;
                } else {
                    // sinon on renvoie vers la liste des abonnements souscrits pour cet article
                    // On utilise URLSearchParams pour contruire les paramètres de l'url avec l'ensemble des
                    // abonnementIds qui ont été souscrits pour cet article
                    urlSearchParams = new URLSearchParams();
                    for (const abonnementId of article.abonnement_souscrit_ids) {
                        urlSearchParams.append("abonnement_id", abonnementId);
                    }
                    urlSearchParams.append("article", article.nom);
                    lienAbos = <Link to={"/abonnements?" + urlSearchParams}><span className="souscris" title="Voir les abonnements">✔ Souscrit</span></Link>
                }
            }

            lis.push(
                <li key={article.id}>
                    <span className="nom-article">{article.nom}</span>
                    {lienAbos}
                    {
                        article.description
                            ? <>
                                <div className="description-article-container">
                                    {article.description.split("\n").map((ligne, i) => <p key={i}>{ligne}</p>)}
                                </div>
                            </>
                            : null
                    }
                    <ul className="liste-prix-article">
                        {article.liste_prix.map((item, i) => (
                            <li key={i}>
                                <span className="prix-libelle">
                                    {item.libelle ? item.libelle : "Prix unitaire"}
                                    {item.souscris ? <span className="souscris">✔</span> : null}
                                </span>
                                <span className="prix-article"><PrixHT prix={item.prix}/></span>
                            </li>
                        ))}
                    </ul>
                </li>
            )
        });
        return <ul className="liste-articles-modele-abonnement">{lis}</ul>;
    }
}