import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import LoggedOutWithHeader from "../../components/ui/LoggedOutWithHeader";
import "./errorBoundary.css";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    // Si une erreur survient, on met à jour l'état
    static getDerivedStateFromError(error) {
        return {
            hasError: true,
            error
        };
    }

    render() {

        const contenuErreur = (
            <section className="section-contenu-page page-statique page-erreur">
                <FontAwesomeIcon icon={solid("exclamation-circle")} className="icone-erreur"/>
                <h1>
                    Une erreur s'est produite
                </h1>
                {
                    this.state.hasError && this.state.error.message
                    ? <details className="message-erreur-container">
                        <summary>Informations techniques</summary>
                        {this.state.error.message}
                    </details>
                    : null
                }
                <p>
                    Veuillez recharger la page ou <a href="/logout">vous reconnecter</a>.
                </p>
                <p>
                    Si l'erreur persistait, contactez-nous à l'adresse <a href="mailto:contact@numerian.fr">contact@numerian.fr</a>
                </p>
            </section>
        )

        // Si une erreur survient, on affiche un message spécifique
        if (this.state.hasError) {
            return (
                this.props.isPageLoggedOut
                ? <LoggedOutWithHeader>{contenuErreur}</LoggedOutWithHeader>
                : contenuErreur
            )
        }

        return this.props.children;
    }
}

export default ErrorBoundary;