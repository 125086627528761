import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PrimaryButton from "../../components/ui/PrimaryButton";
import Input from "../../components/forms/Input";
import "./login.css";
import  CurrentUserContext  from "../../context/CurrentUserContext";

async function loginUser(credentials, setToken, setErrorText, setCurrentUserAndPersist) {
    try {
        const response = await fetch(`${API_URL}/login`, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(credentials),
        })
        if(!response.ok) {
            throw new Error();
        }

        const data = await response.json();
        await getUserInfo(data.token, setCurrentUserAndPersist);
        // On fait le setToken une fois qu'on a récupéré les informations de l'utilisateur,
        // car c'est l'existence du token qui fait qu'on affiche la page d'accueil.
        // Donc si on set le token avant d'avoir récupéré les infos utilisateurs, on ne pourra
        // pas construire correctement les URLs vers l'API avec la réference externe de l'utilisateur.
        setToken(data);

    } catch {
        setErrorText("Mauvais identifiant et/ou mot de passe.")
    }
}

async function getUserInfo(token, setCurrentUserAndPersist) {
    let userName = null;
    let userCollectivite = null;
    let userMenus = [];
    try {
        const dataUserResponse = await fetch(`${API_URL}/user`, {
            method: "GET",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        });
        if (dataUserResponse.ok) {
            const userInfoJson = await dataUserResponse.json();
            userName = userInfoJson?.nom || null;
            userMenus = userInfoJson?.menus || [];
        } else {
            throw new Error("not ok");
        }
    } catch {}
    try {
        const collectiviteResponse = await fetch(
            `${API_URL}/collectivite`,
            {
                method: "GET",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        if (collectiviteResponse.ok) {
            const collectiviteResponseJson = await collectiviteResponse.json();
            userCollectivite = collectiviteResponseJson?.nom || null;
        } else {
            throw new Error("not ok");
        }
    } catch { }

    setCurrentUserAndPersist({
        name: userName,
        collectivite: userCollectivite,
        menus: userMenus,
    })
}

export default function Login({ setToken }) {
    const [errorText, setErrorText] = useState("");
    const { setCurrentUserAndPersist } = useContext(CurrentUserContext);

    const handleSubmit = (e) => {
        e.preventDefault();
        const data = new FormData(e.target);
        loginUser(
            {
                email: data.get("useremail"),
                password: data.get("password"),
            },
            setToken,
            setErrorText,
            setCurrentUserAndPersist
        );
    };

    return (
        <>
            <div id="main-connection">
                <div id="connection-left">
                    <img
                        className="connection-logo"
                        src="/img/logo_mynumerian.svg"
                        alt="logo"
                    />
                    <div className={"error-msg" + (errorText === "" ? " error-msg-hidden" : "")}>
                        {errorText}
                    </div>
                    <form
                        className="connection-form"
                        onSubmit={handleSubmit}>
                        <div className="input-element">
                            <label htmlFor="useremail">Votre email</label>
                            <div className="input-with-icon-container">
                                <span>
                                    <FontAwesomeIcon icon={solid("envelope")} />
                                </span>
                                <Input
                                    name={"useremail"}
                                    id={"useremail"}
                                    placeholder={"monadresse@zimbra.fr"}
                                    type={"email"}
                                    disabled={false}
                                    required={true}
                                    className={"input-with-icon"}
                                />
                            </div>
                        </div>
                        <div className="input-element">
                            <label htmlFor="password">Votre mot de passe</label>
                            <div className="input-with-icon-container">
                                <span>
                                    <FontAwesomeIcon icon={solid("key")} />
                                </span>
                                <Input
                                    name={"password"}
                                    id={"password"}
                                    placeholder={"************"}
                                    type={"password"}
                                    disabled={false}
                                    required={true}
                                    className={"input-with-icon"}
                                />
                            </div>
                        </div>
                        <PrimaryButton>Se connecter</PrimaryButton>
                    </form>
                    <Link className="mdp-oublie-link" to="/password/recover">Mot de passe oublié</Link>
                    <Link className="inscription-link" to="/public/formulaire_inscription">Demander la création de mon compte</Link>
                    <div className="lien-services-container">
                        <Link className="secondary-btn" to="/public/services">Voir nos services</Link>
                    </div>
                </div>
                <div id="connection-right"></div>
            </div>
        </>
    );
}
