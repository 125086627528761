import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

import "./buttons.css";

export default function BoutonTri(props) {
    const isActif = props.cleTri === props.cle;
    const className =
        "bouton-tri " +
        (isActif ? "actif tri-" + props.directionTri.toLowerCase() : "");

    return (
        <button className={className} onClick={() => props.handleOnClick(props.cle)}>
            {props.children}
            {isActif ? (
                props.directionTri === "ASC" ? (
                    <FontAwesomeIcon size="sm" icon={solid("arrow-up")} />
                ) : (
                    <FontAwesomeIcon size="sm" icon={solid("arrow-down")} />
                )
            ) : null}
        </button>
    );
}
