import { createContext, useState } from "react";

export const CurrentUserContext = createContext({})

const LOCAL_STORAGE_KEY_USERNAME = 'userName';
const LOCAL_STORAGE_KEY_COLLECTIVITE = 'userCollectivite';
const LOCAL_STORAGE_KEY_MENUS = 'userMenus';

const propertyToLocalStorageKey = {
    name: LOCAL_STORAGE_KEY_USERNAME,
    collectivite: LOCAL_STORAGE_KEY_COLLECTIVITE,
    menus: LOCAL_STORAGE_KEY_MENUS,
}

export const CurrentUserProvider = ({ children }) => {

    let name = null;
    let collectivite = null;
    let menus = [];

    // try/catch pour ne pas planter au cas où l'info soit mal formatée dans localStorage
    try {
        name = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY_USERNAME));
        collectivite = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY_COLLECTIVITE));
        menus = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY_MENUS));
    } catch(e) {
        // localStorage mal formé, on continue l'exécution, l'affichage sera dégradé mais pas planté
    }

    const [currentUser, setCurrentUser] = useState({
        name: name,
        collectivite: collectivite,
        menus: menus,
    });

    const setCurrentUserAndPersist = (currentUser, setState = true) => {
        if (currentUser == null) {
            for (const prop in propertyToLocalStorageKey) {
                localStorage.removeItem(propertyToLocalStorageKey[prop]);
            }
        } else {
            for (const prop in propertyToLocalStorageKey) {
                if (currentUser[prop] !== null) {
                    localStorage.setItem(propertyToLocalStorageKey[prop], JSON.stringify(currentUser[prop]));
                } else {
                    localStorage.removeItem(propertyToLocalStorageKey[prop]);
                }
            }
        }
        if (setState){
            setCurrentUser(currentUser);
        }
    }

    return (
        <CurrentUserContext.Provider value={{ currentUser, setCurrentUserAndPersist }}>
            {children}
        </CurrentUserContext.Provider>
    )
}

export default CurrentUserContext;
