import React, { useState } from "react";
import Header from "./home/components/header/header";
import Menu from "./home/components/menu/menu";

export default function Root(props) {

    const [displaySmallScreenMenu, setDisplaySmallScreenMenu] = useState(false);

    function handleSmallScreenMenuClick() {
        setDisplaySmallScreenMenu((dm) => !dm);
    }

    return (
        <>
            <Header handleClick={handleSmallScreenMenuClick} />
            <Menu
                handleClick={handleSmallScreenMenuClick}
                displaySmallScreenMenu={displaySmallScreenMenu}
            />
            <main>
                {
                    props.loading
                        ? <div className="chargement-route-container"><span className="spinner-loader"></span><br/>Chargement en cours</div>
                        : props.children || "ICI le contenu"
                }
            </main>
        </>
    );
}
